import { useEffect, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import {
  READ_DAO_CONTRACT,
  WRITE_DAO_CONTRACT,
} from "../../configs/smart_contracts";
import { store } from "../../store";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";

function CreateCommission(props: any) {
  const nav = useNavigate();

  const [account] = store.useState("account");

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState("");

  const init = async () => {
    setOwner(await READ_DAO_CONTRACT.methods.owner().call());
  };

  const isOwner = useMemo(() => {
    return account === owner;
  }, [account, owner]);

  useEffect(() => {
    init();
  }, []);

  const createCommissionHandler = async () => {
    if (name === "") {
      return toast("Please input name", customToastStyle);
    }

    try {
      setLoading(true);
      const writeDaoContract = await WRITE_DAO_CONTRACT();
      await writeDaoContract.methods
        .createCommission(name, 0)
        .send({
          from: account,
          gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
        });

      toast("Create commission successfully", customToastSuccessStyle);

      nav("/dao/commissions");
    } catch (e) {
      return toast("Error creating commission", customToastStyle);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form>
      {!isOwner && (
        <Alert variant={"danger"}>
          Only owner can create a new commission.
        </Alert>
      )}

      <Form.Group className="mb-3" controlId="amount">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="create vest">
        <Button
          variant="primary"
          onClick={createCommissionHandler}
          disabled={loading || !isOwner}
        >
          Create Commission
        </Button>
      </Form.Group>
    </Form>
  );
}

export default CreateCommission;
