import { useMemo, useEffect, useState } from "react";
import { Image, Button, Form, Alert } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import BigNumber from "bignumber.js";
import Web3 from "web3";

import { store } from "../../store";
import { DAO_ADDRESS } from "../../configs/addresses";
import {
  READ_ERC20LOP_CONTRACT,
  WRITE_DAO_CONTRACT,
  READ_DAO_CONTRACT,
  WRITE_ERC20LOP_CONTRACT,
} from "../../configs/smart_contracts";

import { BgColor } from "../../App";
import EtherscanImg from "../../assets/img/etherscan.png";
import TokenlogoImg from "../../assets/img/token-logo.png";
import BgImg from "../../assets/img/bg.png";
import toast from "react-hot-toast";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";
import { getLocaleString } from "../../utils";

function Stake(props: any) {
  const [stakeAmount, setstakeAmount] = useState("");
  const [stakedAmount, setstakedAmount] = useState("0");
  const [lopPrice, setLopPrice] = useState("0");
  const [loading, setLoading] = useState(false);
  const [lopTotalSupply, setlopTotalSupply] = useState("");
  const [votingIndices, setvotingIndices] = useState([]);
  const [withdrawEnabled, setWithdrawEnabled] = useState(true);

  const [lBalance, setLBalance] = store.useState("lBalance");
  const [account] = store.useState("account");

  useEffect(() => {
    (async () => {
      if (account) {
        const _lBalance = await READ_ERC20LOP_CONTRACT.methods
          .balanceOf(account)
          .call();
        setLBalance(_lBalance?.toString() || "0");
      }
    })();
  }, [account, setLBalance]);

  useEffect(() => {
    (async () => {
      if (account) {
        const _stakedAmount = await READ_DAO_CONTRACT.methods
          .stakeInfo(account)
          .call();
        setstakedAmount(
          Web3.utils.fromWei(_stakedAmount?.toString() || "", "ether")
        );
      }
    })();
  }, [account, setstakedAmount]);

  useEffect(() => {
    (async () => {
      if (account) {
        const _votingIndices: any = [];
        let _pointer = 0;
        while (true) {
          try {
            const _index = await READ_DAO_CONTRACT.methods
              .votingIndices(account, _pointer)
              .call();
            _votingIndices.push(_index);
            const _proposalInfo: any = await READ_DAO_CONTRACT.methods
              .getProposal(_index)
              .call();
            if (parseInt(_proposalInfo?.status) === 1) {
              setWithdrawEnabled(false);
              break;
            }
            _pointer++;
          } catch (e) {
            break;
          }
        }
        setvotingIndices(_votingIndices);
      }
    })();
  }, [account, setstakedAmount]);

  useEffect(() => {
    getInitialData();
  }, []);

  const viewLBalance = useMemo(() => {
    return Web3.utils.fromWei(lBalance?.toString() || "0", "ether");
  }, [lBalance]);

  const viewTotalBalance = useMemo(() => {
    const amount = parseFloat(stakedAmount) + parseFloat(viewLBalance);
    return amount || 0;
  }, [viewLBalance, stakedAmount]);

  const viewLBalancePrice = useMemo(() => {
    return parseFloat(viewLBalance) * parseFloat(lopPrice) || 0;
  }, [viewLBalance, lopPrice]);

  const availableStakeAmount = useMemo(() => {
    return parseFloat(viewLBalance) - 0.1;
  }, [viewLBalance]);

  const getInitialData = async () => {
    const _lopTotalSupply = await READ_DAO_CONTRACT.methods
      .lopTotalSupply()
      .call();
    setlopTotalSupply(
      Web3.utils.fromWei(_lopTotalSupply?.toString() || "", "ether")
    );

    const _erc20LopPrice = await READ_ERC20LOP_CONTRACT.methods
      .tokenPrice()
      .call();
    const _erc20LopPriceDecimal = await READ_ERC20LOP_CONTRACT.methods
      .tokenPriceDecimal()
      .call();
    setLopPrice(
      BigNumber(_erc20LopPrice?.toString() || "0")
        .div(BigNumber(_erc20LopPriceDecimal?.toString() || "1"))
        .toString()
    );

    if (account) {
      const _stakedAmount = await READ_DAO_CONTRACT.methods
        .stakeInfo(account)
        .call();
      setstakedAmount(
        Web3.utils.fromWei(_stakedAmount?.toString() || "", "ether")
      );
    }
  };

  const stakeHandler = async () => {
    if (loading) {
      return toast("Swapping token now.", customToastStyle);
    }
    if (!account) {
      return toast("Please connect your wallet to continue", customToastStyle);
    }
    if (parseFloat(stakeAmount) <= 0 || isNaN(parseFloat(stakeAmount))) {
      return toast("Please input LOP amount", customToastStyle);
    }
    if (parseFloat(viewLBalance) - parseFloat(stakeAmount) < 0.1) {
      return toast(
        "You should hold as least 0.1 LOP to take part in voting process.",
        customToastStyle
      );
    }
    if (parseFloat(stakeAmount) > parseFloat(viewLBalance)) {
      return toast("Exceeds LOP amount.", customToastStyle);
    }

    try {
      setLoading(true);

      const erc20LopContract = await WRITE_ERC20LOP_CONTRACT();
      await erc20LopContract.methods
        .approve(DAO_ADDRESS, Web3.utils.toWei(stakeAmount, "ether"))
        .send({
          from: account,
          gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
        });

      const daoContract = await WRITE_DAO_CONTRACT();
      await daoContract.methods
        .stake(Web3.utils.toWei(stakeAmount, "ether"))
        .send({
          from: account,
          gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
        });

      await getInitialData();

      setLoading(false);
      setstakeAmount("");

      return toast("Stake successfully.", customToastSuccessStyle);
    } catch (e) {
      console.error(e);
      setLoading(false);
      return toast("Error to swap token", customToastStyle);
    }
  };

  const withdrawHandler = async () => {
    if (loading) {
      return toast("Swapping token now.", customToastStyle);
    }
    if (!account) {
      return toast("Please connect your wallet to continue", customToastStyle);
    }
    if (parseFloat(stakeAmount) <= 0 || isNaN(parseFloat(stakeAmount))) {
      return toast("Please input LOP amount", customToastStyle);
    }
    if (parseFloat(stakeAmount) > parseFloat(stakedAmount)) {
      return toast("Exceeds stake amount.", customToastStyle);
    }

    try {
      setLoading(true);

      const daoContract = await WRITE_DAO_CONTRACT();
      await daoContract.methods
        .unstake(Web3.utils.toWei(stakeAmount, "ether"))
        .send({
          from: account,
          gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
        });

      await getInitialData();

      setLoading(false);
      setstakeAmount("");

      return toast("Withdraw successfully.", customToastSuccessStyle);
    } catch (e) {
      setLoading(false);
      return toast("Error to swap token", customToastStyle);
    }
  };

  const stakeInputHandler = (e: any) => {
    setstakeAmount(e.target.value);
  };

  return (
    <div className="page-container">
      <Image id="bg-img" src={BgImg} alt="background" />
      <div className="token-container d-md-flex align-items-start">
        <div className="left-side">
          <div className="card-content pt-4">
            <h2 className="font-24 inter-bold">Staking Rule</h2>
            <p className="inter-regular color-grey font-14">
              You can vote proposals after stake LOP token and can't withdraw it
              while your voted proposals are executed.
            </p>
          </div>
          <div className="card-content mt-2 mt-md-3">
            <div className="d-flex align-items-center">
              <p className="inter-regular color-grey font-14 m-0">
                LOP token owned
              </p>
              <Image
                src={EtherscanImg}
                width={16}
                alt="etherscan"
                className="mx-2"
              />
            </div>
            <div className="d-flex align-items-start mt-2">
              <Image src={TokenlogoImg} width={25} alt="etherscan" />
              <div className="">
                <div className="inter-bold font-20 m-0 mx-2">
                  {getLocaleString(viewTotalBalance)} LOP
                </div>
                {/* <div>({getLocaleString(viewLBalancePrice)} USDC)</div> */}
              </div>
            </div>
          </div>
          <div className="card-content mt-2 mt-md-3">
            <div className="d-flex align-items-center">
              <p className="inter-regular color-grey font-14 m-0">
                LOP Total Supply
              </p>
              <Image
                src={EtherscanImg}
                width={16}
                alt="etherscan"
                className="mx-2"
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <Image src={TokenlogoImg} width={25} alt="etherscan" />
              <p className="inter-bold font-20 m-0 mx-2">
                {getLocaleString(lopTotalSupply)} LOP
              </p>
            </div>
          </div>
        </div>
        <div className="right-side card-content mx-md-5 px-2 d-flex justify-content-center">
          <div style={{ width: "100%", maxWidth: 435, padding: "40px 0" }}>
            <h3 className="inter-bold font-24 text-center mb-4">
              Stake LOP Token
            </h3>
            <Card className="mb-3">
              <Card.Body>
                <Card.Text>
                  <b>Staked amount:</b> {getLocaleString(stakedAmount)}(LOP)
                </Card.Text>
              </Card.Body>
            </Card>
            {!withdrawEnabled && (
              <Alert variant="info">
                You can't withdraw token while your voted proposals are executed
              </Alert>
            )}
            <div className="card-content bg-grey px-4 d-flex justify-content-between align-items-center position-relative">
              <div>
                <p className="m-0 font-12 color-grey inter-regular mb-1">
                  Amount{" "}
                  {availableStakeAmount > 0 &&
                    `(0 ~ ${getLocaleString(availableStakeAmount)})`}
                </p>
                <Form.Control
                  type="text"
                  placeholder="LOP"
                  value={stakeAmount}
                  onChange={stakeInputHandler}
                />
              </div>
              <div className="card-content d-flex align-items-center p-2">
                <Image src={TokenlogoImg} width={25} alt="usdc" />
                <div
                  className="inter-bold font-16 color-grey"
                  style={{ marginLeft: 5 }}
                >
                  LOP
                </div>
              </div>
            </div>
            <ButtonGroup
              aria-label="Basic example"
              className="inter-bold font-20 w-100 border-0 border-10 mt-4"
            >
              <Button
                className="inter-bold font-20 w-100 border-0 border-10 mt-4"
                style={{
                  background: BgColor.DARKBLUE,
                  padding: "7px 0",
                  opacity: loading ? 0.4 : 1,
                }}
                onClick={stakeHandler}
              >
                Stake
              </Button>
              <Button
                className="inter-bold font-20 w-100 border-0 border-10 mt-4"
                style={{
                  background: BgColor.RED,
                  padding: "7px 0",
                }}
                disabled={loading || !withdrawEnabled}
                onClick={withdrawHandler}
              >
                Withdraw
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stake;
