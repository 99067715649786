import { useState, useEffect, useMemo } from "react";
import { Image, Button } from "react-bootstrap";
import { BsCaretRightFill } from "react-icons/bs";
import BigNumber from "bignumber.js";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Web3 from "web3";

import toast from "react-hot-toast";

import { store } from "../store";
import { READ_DAO_CONTRACT, READ_ERC20_R_LOP_CONTRACT, READ_ERC20LOP_CONTRACT, READ_USDC_CONTRACT, WRITE_DAO_CONTRACT, WRITE_ERC20_R_LOP_CONTRACT } from "../configs/smart_contracts";
import { USDC_DECIMALS } from "../configs/constants";

import Layout from "../layout";
import { BgColor } from "../App";
import { getLocaleString } from "../utils";
import BgImg from "../assets/img/bg.png";
import Tokenlogo1Img from "../assets/img/token-logo1.png";
import TokenlogoImg from "../assets/img/token-logo.png";
import USDClogoImg from "../assets/img/usdc-logo.png";
import { customToastStyle } from "../configs/constants";
import { DAO_ADDRESS } from "../configs/addresses";

function TokenOption(props: any) {
  const [rAmount, setRAmount] = useState("");
  const [convertLopAmount, setConvertLopAmount] = useState("0");
  const [convertUsdcAmount, setConvertUsdcAmount] = useState("0");
  const [currentconvertUsdcAmount, setcurrentConvertUsdcAmount] = useState("0");
  const [rMintIndices, setRMintIndices] = useState([]);
  const [rMintList, setRMintList]: [rMintList: any, setRMintList: any] = useState([]);
  const [swapOptionIndex, setSwapOptionIndex] = useState(-1);
  const [rmintIndex, setRmintIndex] = useState(-1);
  const [currentLopPrice, setCurrentLopPrice] = useState("0");

  const [loading, setLoading] = useState(false);


  const [account] = store.useState("account");
  const [rBalance, setRBalance] = store.useState("rBalance");
  const [usdcBalance, setUsdcBalance] = store.useState("usdcBalance");
  const [erc20RLopTotalSupply, setErc20RLopTotalSupply] = store.useState("erc20RLopTotalSupply");
  const [swapStatus, setSwapStatus] = store.useState("swapStatus");
  const [treasuryAmount, setTreasuryAmount] = store.useState("treasuryAmount");

  const erc20LopPrice = useMemo(() => {
    const item: any = rMintList[swapOptionIndex];
    const rLopPrice = Math.min(item?.price / item?.priceDecimal)
    return rLopPrice || 0;
  }, [rMintList, swapOptionIndex])

  const getInitialData = async () => {
    const _erc20LopPrice = await READ_ERC20LOP_CONTRACT.methods.tokenPrice().call();
    const _erc20LopPriceDecimal = await READ_ERC20LOP_CONTRACT.methods.tokenPriceDecimal().call();
    setCurrentLopPrice(BigNumber(_erc20LopPrice?.toString() || "0").div(BigNumber(_erc20LopPriceDecimal?.toString() || "1")).toString());

    const _erc20RTtoalSupply = await READ_DAO_CONTRACT.methods.rlopSupplyLimit().call();
    setErc20RLopTotalSupply(_erc20RTtoalSupply?.toString() || "0");

    const _swapStatus = await READ_DAO_CONTRACT.methods.swapStatus().call();
    setSwapStatus(_swapStatus);

    const _treasuryAmount = await READ_USDC_CONTRACT.methods.balanceOf(DAO_ADDRESS).call();
    setTreasuryAmount(_treasuryAmount?.toString() || "0");
  }

  const getRMintIndices = async (account: any) => {
    if (account) {
      const _rMintIndices: any = [];
      let _rmintIndex = 0
      while (true) {
        try {
          _rMintIndices.push(await READ_DAO_CONTRACT.methods.rMintIndices(account, _rmintIndex).call());
          _rmintIndex++;
        } catch (e: any) {
          break;
        }
      }
      setRMintIndices(_rMintIndices);
    }
  }

  const getRMintInfo = async () => {
    const _rMintInfos: any = [];
    for (let i = 0; i < rMintIndices.length; i++) {
      const rMintInfo: any = await READ_DAO_CONTRACT.methods.rMintList(rMintIndices[i]).call();
      const amount = Web3.utils.fromWei(rMintInfo?.amount, "ether");
      const price = rMintInfo?.price.toString();
      const priceDecimal = rMintInfo?.priceDecimal.toString();
      const swapped = rMintInfo?.swapped;
      const swappedAmount = Web3.utils.fromWei(rMintInfo?.swappedAmount, "ether");
      const to = rMintInfo?.to;
      if(!swapped) {
        _rMintInfos.push({ amount, price, priceDecimal, swapped, swappedAmount, to, mintIndex:  rMintIndices[i]});
      }
    }
    setRMintList(_rMintInfos);
  }

  useEffect(() => {
    (async () => {
      if (account) {
        const _rBalance = await READ_ERC20_R_LOP_CONTRACT.methods.balanceOf(account).call();
        setRBalance(_rBalance?.toString() || "0");
      }
    })()
  }, [account, setRBalance])

  useEffect(() => {
    (async () => {
      if (account) {
        const _usdcBalance = await READ_USDC_CONTRACT.methods.balanceOf(account).call();
        setUsdcBalance(_usdcBalance?.toString() || "0");
      }
    })()
  }, [account, setUsdcBalance])

  useEffect(() => {
    getRMintIndices(account);
  }, [account])

  useEffect(() => {
    getRMintInfo()
  }, [rMintIndices])

  useEffect(() => {
    getInitialData();
  }, [])



  const viewErc20RLopTotalSupply = useMemo(() => {
    return BigNumber(Web3.utils.fromWei(erc20RLopTotalSupply?.toString() || "0", "ether")).toFixed(0)
  }, [erc20RLopTotalSupply]);

  const viewRBalance = useMemo(() => {
    return BigNumber(Web3.utils.fromWei(rBalance?.toString() || "0", "ether")).toFixed(0)
  }, [rBalance]);

  const viewUsdcBalance = useMemo(() => {
    return BigNumber((usdcBalance as number) / USDC_DECIMALS).toFixed(5);
  }, [usdcBalance]);

  const viewTreasuryAmount = useMemo(() => {
    return parseFloat(treasuryAmount?.toString() || "0") / USDC_DECIMALS
  }, [treasuryAmount]);


  const handleRMintOption = (item: any, index: number) => {
    setSwapOptionIndex(index)
    setRmintIndex(item?.mintIndex);
  }

  const convertLopHandler = async () => {
    if (loading) {
      return toast("Converting token now.", customToastStyle);
    }

    if (!swapStatus) {
      return toast("Swap is not available now.", customToastStyle);
    }

    if (!account) {
      return toast("Please connect your wallet to continue", customToastStyle);
    }

    if (isNaN(parseFloat(rAmount))) {
      return toast("Swap amount error", customToastStyle);
    }

    if (swapOptionIndex === -1) {
      return toast("Please select swap option", customToastStyle);
    }

    if (!rAmount) {
      return toast("Swap amount error", customToastStyle);
    }

    setLoading(true);

    try {
      const rERC20LopContract = await WRITE_ERC20_R_LOP_CONTRACT();
      await rERC20LopContract.methods.approve(DAO_ADDRESS, Web3.utils.toWei(rAmount, "ether")).send({ from: account, gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0") });

      const daoContract = await WRITE_DAO_CONTRACT();
      await daoContract.methods.swapRLopToLOP(rmintIndex, Web3.utils.toWei(rAmount, "ether")).send({ from: account, gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0") });

      setRAmount("");
      setConvertLopAmount("0")
      setConvertUsdcAmount("0")
      setSwapOptionIndex(-1)

      await getInitialData();
      await getRMintInfo();
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false);
    }
  }

  const convertUsdcHandler = async () => {
    if (loading) {
      return toast("Converting token now.", customToastStyle);
    }

    if (!swapStatus) {
      return toast("Swap is not available now.", customToastStyle);
    }

    if (!account) {
      return toast("Please connect your wallet to continue", customToastStyle);
    }

    if (isNaN(parseFloat(rAmount))) {
      return toast("Swap amount error", customToastStyle);
    }

    if (parseFloat(convertUsdcAmount) > viewTreasuryAmount) {
      return toast("There is not enough USDC options in Treasury", customToastStyle);
    }

    if (swapOptionIndex === -1) {
      return toast("Please select swap option", customToastStyle);
    }

    if (!rAmount) {
      return toast("Swap amount error", customToastStyle);
    }

    setLoading(true);

    try {
      const rERC20LopContract = await WRITE_ERC20_R_LOP_CONTRACT();
      await rERC20LopContract.methods.approve(DAO_ADDRESS, Web3.utils.toWei(rAmount, "ether")).send({ from: account, gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0") });
      const daoContract = await WRITE_DAO_CONTRACT();
      await daoContract.methods.swapRLopToUsdc(rmintIndex, Web3.utils.toWei(rAmount, "ether")).send({ from: account, gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0") });

      setRAmount("");
      setConvertLopAmount("0")
      setConvertUsdcAmount("0")
      setSwapOptionIndex(-1)

      await getInitialData();
      await getRMintInfo();
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false);
    }
  }

  const swapAmountInputHandler = (e: any) => {
    if (parseFloat(e.target.value) > (parseFloat(rMintList[swapOptionIndex]?.amount) - parseFloat(rMintList[swapOptionIndex]?.swappedAmount))) {
      return toast("You don't have enough rLOP options", customToastStyle);
    }
    const item: any = rMintList[swapOptionIndex];
    setConvertLopAmount(e.target.value);
    const rLopPrice = Math.min(item?.price / item?.priceDecimal)
    const _usdcAmount = parseFloat(e.target.value) * parseFloat(rLopPrice?.toString() || "0")
    const _currentusdcAmount = parseFloat(e.target.value) * parseFloat(currentLopPrice?.toString() || "0")
    setConvertUsdcAmount(_usdcAmount.toFixed(5).toString())
    setcurrentConvertUsdcAmount(_currentusdcAmount.toFixed(5).toString());
    setRAmount(e.target.value);
  }

  return (
    <Layout>
      <div className="page-container" style={{ background: BgColor.BLUE }}>
        <Image id="bg-img" src={BgImg} alt="background" />
        <div className="token-container d-md-flex align-items-start">
          <div className="left-side">
            <div className="card-content pt-4 pb-5">
              <h2 className="font-24 inter-bold">My rLOP options</h2>
              <p className="inter-regular color-grey font-14 mt-2 mt-md-3 mb-0">
                rLOP option contracts are used to compensate DAO workers.
              </p>
              <p className="inter-regular color-grey font-14 mt-2 mt-md-3 mb-0">
                rLOP can be either redeemed for USDC or converted for LOP.
              </p>
              <p className="inter-regular color-grey font-14 mt-2 mt-md-3">
                Exercise price are set at the creation of the option contract.
              </p>
            </div>
            <div className="card-content mt-2 mt-md-3">
              <p className="inter-regular color-grey font-14 m-0">
                rLOP options owned
              </p>
              <div className="d-flex align-items-center mt-2">
                <Image src={Tokenlogo1Img} width={25} alt="etherscan" />
                <p className="inter-bold font-20 m-0 mx-2">{getLocaleString(viewRBalance)} rLOP</p>
              </div>
            </div>
            <div className="card-content mt-2 mt-md-3">
              <p className="inter-regular color-grey font-14 m-0">
                USDC tokens owned
              </p>
              <div className="d-flex align-items-center mt-2">
                <Image src={Tokenlogo1Img} width={25} alt="etherscan" />
                <p className="inter-bold font-20 m-0 mx-2">{getLocaleString(viewUsdcBalance)} USDC</p>
              </div>
            </div>
            <div className="card-content mt-2 mt-md-3">
              <p className="inter-regular color-grey font-14 m-0">
                Total rLOP options issued
              </p>
              <div className="d-flex align-items-center mt-2">
                <Image src={Tokenlogo1Img} width={25} alt="etherscan" />
                <p className="inter-bold font-20 m-0 mx-2">{getLocaleString(viewErc20RLopTotalSupply)} rLOP</p>
              </div>
            </div>
          </div>
          <div className="right-side mx-md-5">
            <div className="card-content pb-4">
              <div className="d-flex align-items-center px-3">
                <Image src={Tokenlogo1Img} width={25} alt="etherscan" />
                <p className="inter-bold font-20 m-0 mx-2">
                  rLOP options contract
                </p>
              </div>
              <div className="mt-3 d-md-flex w-100 justify-content-between">
                <div className="card-content bg-grey py-3 col-md-5">
                  <p className="font-12 inter-regular color-grey m-0 mt-1">
                    Redemption value: {getLocaleString(erc20LopPrice?.toString() || "0")} USDC
                  </p>
                  {/* <p className="font-12 inter-regular color-grey m-0">
                    Current LOP value: {viewErc20MarketCap?.toString() || "0"} USDC
                  </p> */}
                  {
                    swapOptionIndex !== -1 &&
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Swapped: {rMintList[swapOptionIndex]?.swappedAmount}(rLOP)</Form.Label>
                    </Form.Group>
                  }
                  {
                    swapOptionIndex !== -1 &&
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Swap Amount</Form.Label>
                      <Form.Control type="text" value={rAmount} onChange={swapAmountInputHandler} />
                    </Form.Group>
                  }
                  <Dropdown className="mt-2">
                    <Dropdown.Toggle variant="secondary" className="w-100">
                      {
                        swapOptionIndex === -1 && rMintList.length > 0 && "Select Swap options"
                      }
                      {
                        swapOptionIndex !== -1 && rMintList.length > 0 && `${rMintList[swapOptionIndex]?.amount} rLOP, Price(${rMintList[swapOptionIndex]?.price / rMintList[swapOptionIndex]?.priceDecimal})`
                      }
                      {
                        rMintList.length === 0 && "No option to select"
                      }
                    </Dropdown.Toggle>

                    {
                      rMintList.length > 0 &&
                      <Dropdown.Menu className="w-100">
                        {
                          rMintList.map((item: any, index: number) =>
                            <Dropdown.Item className="w-100" disabled={item?.swapped} key={index} onClick={() => {
                              handleRMintOption(item, index)
                            }}>
                              {item?.amount} rLOP, Price({item?.price / item?.priceDecimal} USDC)
                            </Dropdown.Item>
                          )
                        }
                      </Dropdown.Menu>
                    }
                  </Dropdown>
                </div>
                <div className="col-md-7">
                  <div className="card-content bg-grey py-2 px-3 d-flex justify-content-between align-items-center mt-2 mt-md-0 mx-md-2">
                    <div
                      className="card-content right-arrow p-1 d-none d-md-block"
                      style={{ background: BgColor.RED }}
                    >
                      <BsCaretRightFill fill="#fff" size={24} />
                    </div>
                    <div>
                      <p className="font-10 inter-regular color-grey mb-1">
                        Exercise price: 1 rLOP = 1 LOP
                      </p>
                      <div className="d-flex align-items-center">
                        <Image src={TokenlogoImg} width={25} alt="etherscan" />
                        <h2 className="inter-bold font-24 m-0 mx-2">
                          {getLocaleString(convertLopAmount)} LOP
                        </h2>
                        ({getLocaleString(currentconvertUsdcAmount)} USDC)
                      </div>
                    </div>
                    <Button
                      className="inter-bold font-14 border-0 border-10"
                      style={{
                        background: BgColor.RED,
                        padding: "7px 30px",
                        width: "min-content",
                        opacity: loading ? 0.4 : 1
                      }}
                      onClick={convertLopHandler}
                    >
                      Convert to LOP
                    </Button>
                  </div>
                  <div className="card-content bg-grey py-2 px-3 d-flex justify-content-between align-items-center mt-2 mx-md-2">
                    <div
                      className="card-content right-arrow p-1 d-none d-md-block"
                      style={{ background: BgColor.DARKBLUE }}
                    >
                      <BsCaretRightFill fill="#fff" size={24} />
                    </div>
                    <div>
                      <p className="font-10 inter-regular color-grey mb-1">
                        Exercise price: 1 rLOP = {getLocaleString(erc20LopPrice?.toString() || "0")} USDC
                      </p>
                      <div className="d-flex align-items-center">
                        <Image src={USDClogoImg} width={25} alt="etherscan" />
                        <h2 className="inter-bold font-24 m-0 mx-2">
                          {getLocaleString(convertUsdcAmount)} USDC
                        </h2>
                      </div>
                    </div>
                    <Button
                      className="inter-bold font-14 border-0 border-10"
                      style={{
                        background: BgColor.DARKBLUE,
                        padding: "7px 30px",
                        width: "min-content",
                        opacity: loading ? 0.4 : 1
                      }}
                      onClick={convertUsdcHandler}
                    >
                      Redeem USDC
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
}

export default TokenOption;
