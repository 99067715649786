import { useEffect, useState, useMemo } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Web3 from "web3";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

import {
  READ_DAO_CONTRACT,
  WRITE_DAO_CONTRACT,
} from "../../configs/smart_contracts";

import { store } from "../../store";

import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";
import { setCommissionApi, getCommissionApi } from "../../apis/vestApi";

function Commissions(props: any) {
  const nav = useNavigate();
  const [account] = store.useState("account");

  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [detailInfo, setDetailInfo]: [
    detailInfo: any,
    setDetailInfo: Function
  ] = useState({});
  const [commissions, setCommissions]: [
    commissions: any,
    setCommissions: Function
  ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [owner, setOwner] = useState("");
  const [member, setMember] = useState("");
  const [contact, setContact] = useState("");
  const [name, setName] = useState("");
  const [commissionId, setCommissionsId] = useState(-1);

  const init = async () => {
    setLoading(true);
    setOwner(await READ_DAO_CONTRACT.methods.owner().call());
    const _commissions = [];
    const _commissionIndex: number = await READ_DAO_CONTRACT.methods
      .commissionIndex()
      .call();
    for (let i = 0; i < _commissionIndex; i++) {
      const commission: any = await READ_DAO_CONTRACT.methods
        .commissions(i)
        .call();
      const name = commission?.name;
      const approvedBudget = Web3.utils.fromWei(
        commission?.approvedBudget,
        "ether"
      );
      const approvedBudgetLOP = Web3.utils.fromWei(
        commission?.approvedBudgetLOP,
        "ether"
      );
      const usedBudget = Web3.utils.fromWei(commission?.usedBudget, "ether");
      const usedBudgetLOP = Web3.utils.fromWei(
        commission?.usedBudgetLOP,
        "ether"
      );

      const memberData =
        (await getCommissionApi({ commissionId: i + "" })) || [];
      const members: any = [];
      debugger;
      let memberIndex = 0;
      while (true) {
        try {
          const address = await READ_DAO_CONTRACT.methods
            .commissionMembers(i, memberIndex)
            .call();
          const data: any = { member: address };

          for (let i = 0; i < memberData.length; i++) {
            if (memberData[i]["member"] === address) {
              data["contact"] = memberData[i]["contact"];
              data["name"] = memberData[i]["name"];
            }
          }

          members.push(data);
          memberIndex++;
        } catch (e) {
          break;
        }
      }

      _commissions.push({
        id: i,
        name,
        approvedBudget,
        approvedBudgetLOP,
        usedBudget,
        usedBudgetLOP,
        members,
      });
    }
    setCommissions([..._commissions]);
    setLoading(false);
  };

  const isCreator = useMemo(() => {
    return account === owner;
  }, [account, owner]);

  useEffect(() => {
    init();
  }, [account]);

  const createCommissionHandler = () => {
    nav("/dao/create_commission");
  };

  const handleClose = () => setShow(false);

  const showAddMember = (id: number) => {
    setShow(true);
    setCommissionsId(id);
  };

  const addMemberHandler = async () => {
    if (!name) {
      toast("Please enter name.", customToastStyle);
      return;
    }
    if (!contact) {
      toast("Please enter contact.", customToastStyle);
      return;
    }
    if (!member) {
      toast("Please enter user address.", customToastStyle);
      return;
    }
    setModalLoading(true);
    try {
      const writeContact = await WRITE_DAO_CONTRACT();
      await writeContact.methods
        .addMemberToCommission(commissionId, member)
        .send({ from: account });

      await setCommissionApi({ commissionId, name, contact, member });

      toast(
        "Added member to commission successfully.",
        customToastSuccessStyle
      );

      setName("");
      setContact("");
      setMember("");
      setShowCreate(false);
      init();
    } catch (e) {
      toast("Failed to add member to commission.", customToastStyle);
    } finally {
      setModalLoading(false);
    }
  };

  const removeMemberHandler = async (commissionId: number, member: string) => {
    setModalLoading(true);
    try {
      const writeContact = await WRITE_DAO_CONTRACT();
      await writeContact.methods
        .removeMemberFromCommission(commissionId, member)
        .send({ from: account });

      toast(
        "Remove member from commission successfully.",
        customToastSuccessStyle
      );
      setMember("");
      setShow(false);
      init();
    } catch (e) {
      toast("Failed to remove member from commission.", customToastStyle);
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Commission Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Members</Form.Label>
            <ListGroup>
              {commissions[commissionId]?.members &&
                commissions[commissionId].members.map((member: any) => (
                  <ListGroup.Item key={uuidv4()}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowDetail(true);
                          setDetailInfo(member);
                        }}
                      >
                        {member?.member}
                      </div>
                      {isCreator && (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            removeMemberHandler(commissionId, member?.member);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="16px"
                            height="16px"
                          >
                            <path d="M 6.496094 1 C 5.675781 1 5 1.675781 5 2.496094 L 5 3 L 2 3 L 2 4 L 3 4 L 3 12.5 C 3 13.328125 3.671875 14 4.5 14 L 10.5 14 C 11.328125 14 12 13.328125 12 12.5 L 12 4 L 13 4 L 13 3 L 10 3 L 10 2.496094 C 10 1.675781 9.324219 1 8.503906 1 Z M 6.496094 2 L 8.503906 2 C 8.785156 2 9 2.214844 9 2.496094 L 9 3 L 6 3 L 6 2.496094 C 6 2.214844 6.214844 2 6.496094 2 Z M 5 5 L 6 5 L 6 12 L 5 12 Z M 7 5 L 8 5 L 8 12 L 7 12 Z M 9 5 L 10 5 L 10 12 L 9 12 Z" />
                          </svg>
                        </div>
                      )}
                    </div>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {isCreator && (
            <Button
              variant="primary"
              onClick={() => setShowCreate(true)}
              disabled={modalLoading}
            >
              Add member to commission
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detail Commission Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Card style={{ marginTop: 20 }}>
              <Card.Body>
                <Form.Label className="mt-1">Name</Form.Label>
                <div>{detailInfo?.name || "Unknown"}</div>
                <Form.Label className="mt-1">Contact</Form.Label>
                <div>{detailInfo?.contact || "Unknown"}</div>
                <Form.Label className="mt-1">Wallet address</Form.Label>
                <div>{detailInfo?.member || "Unknown"}</div>
              </Card.Body>
            </Card>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetail(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCreate} onHide={() => setShowCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Commission member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Card style={{ marginTop: 20 }}>
              <Card.Body>
                <Form.Label className="mt-1">Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Label className="mt-1">Contact</Form.Label>
                <Form.Control
                  type="text"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
                <Form.Label className="mt-1">Wallet address</Form.Label>
                <Form.Control
                  type="text"
                  value={member}
                  onChange={(e) => setMember(e.target.value)}
                />
              </Card.Body>
            </Card>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreate(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={addMemberHandler}
            disabled={modalLoading}
          >
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>
      {isCreator && (
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            flexWrap: "nowrap",
            marginBottom: 20,
          }}
        >
          <Button onClick={createCommissionHandler}>Create Commission</Button>
        </div>
      )}

      <DataTable
        columns={[
          {
            name: "Creator",
            selector: (item: any) => item?.name,
            sortable: true,
          },
          {
            name: "Approved Budget LOP",
            selector: (item: any) => item?.approvedBudgetLOP,
            sortable: true,
          },
          {
            name: "Used Budget LOP",
            selector: (item: any) => item?.usedBudgetLOP,
            sortable: true,
          },
          {
            name: "Approved Budget rLOP",
            selector: (item: any) => item?.approvedBudget,
            sortable: true,
          },
          {
            name: "Used Budget rLOP",
            selector: (item: any) => item?.usedBudget,
            sortable: true,
          },
          {
            name: "Members",
            selector: (item: any) => item?.members?.length,
            sortable: false,
          },
          {
            name: "Action",
            selector: (item: any) => (
              <Button
                variant="primary"
                size="sm"
                onClick={() => showAddMember(item?.id)}
              >
                View Member
              </Button>
            ),
            sortable: false,
          },
        ]}
        data={commissions as any}
        progressPending={loading}
        pagination
      />
    </div>
  );
}

export default Commissions;
