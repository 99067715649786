import { useState, useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import BigNumber from "bignumber.js";
import { AiOutlineClose } from "react-icons/ai";

// @ts-ignore
import * as S3FileUpload from "react-s3";
import Web3 from "web3";

import {
  WRITE_DAO_CONTRACT,
  READ_DAO_CONTRACT,
  READ_ERC20LOP_CONTRACT,
  READ_ERC20_R_LOP_CONTRACT,
} from "../../configs/smart_contracts";
import { store } from "../../store";

import { uploadFile } from "../../utils/cloud";
import { getLocaleString } from "../../utils";
import { customToastStyle } from "../../configs/constants";

function Projects(props: any) {
  const [account] = store.useState("account");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [type, setType] = useState("0");
  const [amount, setAmount] = useState("");
  const [period, setPeriod] = useState("");
  const [selectedCommission, setSelectedCommission] = useState("");
  const [commissions, setCommissions] = useState([]);
  const [isLeadership, setisLeadership] = useState(false);
  const [lopBalance, setlopBalance] = useState(0);
  const [isCommissionMember, setisCommissionMember] = useState(false);
  const [isOwner, setisOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [lopDaoPoolLimit, setlopDaoPoolLimit] = useState("");
  const [lopDaoPoolused, setlopDaoPoolused] = useState("");
  const [lopSupplyLimit, setlopSupplyLimit] = useState("");
  const [lopTotalSupply, setlopTotalSupply] = useState("");
  const [rlopSupplyLimit, setrlopSupplyLimit] = useState("");
  const [rlopTotalSupply, setrlopTotalSupply] = useState("");
  const [stakeAmount, setstakeAmount] = useState("");
  const [lopInvestmentPoolLimit, setlopInvestmentPoolLimit] = useState("");
  const [lopInvestmentPoolused, setlopInvestmentPoolused] = useState("");

  const [erc20LopPrice, setErc20LopPrice] = store.useState("erc20LopPrice");

  const nav = useNavigate();

  const amountToUsd = useMemo(() => {
    return parseFloat(amount) * parseFloat(erc20LopPrice as string) || 0;
  }, [amount, erc20LopPrice]);

  useEffect(() => {
    (async () => {
      const commissionIndex = await READ_DAO_CONTRACT.methods
        .commissionIndex()
        .call();
      const _commissions: any = [];
      for (let i = 0; i < parseInt(commissionIndex?.toString() || "0"); i++) {
        const commission: any = await READ_DAO_CONTRACT.methods
          .commissions(i)
          .call();
        _commissions.push({
          name: commission?.name,
          approvedBudget: Web3.utils.fromWei(
            commission?.approvedBudget || "0",
            "ether"
          ),
          usedBudget: Web3.utils.fromWei(
            commission?.usedBudget || "0",
            "ether"
          ),
          id: i,
        });
      }
      setCommissions(_commissions);

      const _lopDaoPoolLimit = await READ_DAO_CONTRACT.methods
        .lopDaoPoolLimit()
        .call();
      setlopDaoPoolLimit(
        Web3.utils.fromWei(_lopDaoPoolLimit?.toString() || "", "ether")
      );
      const _lopDaoPoolused = await READ_DAO_CONTRACT.methods
        .lopDaoPoolused()
        .call();
      setlopDaoPoolused(
        Web3.utils.fromWei(_lopDaoPoolused?.toString() || "", "ether")
      );
      const _lopSupplyLimit = await READ_DAO_CONTRACT.methods
        .lopTotalSupply()
        .call();
      setlopSupplyLimit(
        Web3.utils.fromWei(_lopSupplyLimit?.toString() || "", "ether")
      );
      const _lopTotalSupply = await READ_ERC20LOP_CONTRACT.methods
        .totalSupply()
        .call();
      setlopTotalSupply(
        BigNumber(
          Web3.utils.fromWei(_lopTotalSupply?.toString() || "", "ether")
        )
          .toFixed(2)
          .toString()
      );
      const _rlopSupplyLimit = await READ_DAO_CONTRACT.methods
        .rlopSupplyLimit()
        .call();
      setrlopSupplyLimit(
        Web3.utils.fromWei(_rlopSupplyLimit?.toString() || "", "ether")
      );
      const _rlopTotalSupply = await READ_DAO_CONTRACT.methods
        .rlopSupplyUsed()
        .call();
      setrlopTotalSupply(
        Web3.utils.fromWei(_rlopTotalSupply?.toString() || "", "ether")
      );
      const _lopInvestmentPoolLimit = await READ_DAO_CONTRACT.methods
        .lopInvestmentPoolLimit()
        .call();
      setlopInvestmentPoolLimit(
        Web3.utils.fromWei(_lopInvestmentPoolLimit?.toString() || "", "ether")
      );
      const _lopInvestmentPoolused = await READ_DAO_CONTRACT.methods
        .lopInvestmentPoolused()
        .call();
      setlopInvestmentPoolused(
        Web3.utils.fromWei(_lopInvestmentPoolused?.toString() || "", "ether")
      );

      const _erc20LopPrice = await READ_ERC20LOP_CONTRACT.methods
        .tokenPrice()
        .call();
      const _erc20LopPriceDecimal = await READ_ERC20LOP_CONTRACT.methods
        .tokenPriceDecimal()
        .call();
      setErc20LopPrice(
        BigNumber(_erc20LopPrice?.toString() || "0")
          .div(BigNumber(_erc20LopPriceDecimal?.toString() || "1"))
          .toString()
      );
    })();
  }, []);

  useEffect(() => {
    if (account) {
      (async () => {
        const isLeadershipResult: boolean = await READ_DAO_CONTRACT.methods
          .isLeadership(account)
          .call();
        const owner = await READ_DAO_CONTRACT.methods.owner().call();
        setisLeadership(isLeadershipResult);
        setisOwner(account === owner);
        const _lopBalance = await READ_ERC20LOP_CONTRACT.methods
          .balanceOf(account)
          .call();
        setlopBalance(
          parseFloat(
            Web3.utils.fromWei(_lopBalance?.toString() || "0", "ether")
          )
        );
        const _stakeAmount: any = await READ_DAO_CONTRACT.methods
          .stakeInfo(account)
          .call();
        setstakeAmount(
          Web3.utils.fromWei(_stakeAmount?.toString() || "0", "ether")
        );
      })();
    }
  }, [account]);

  useEffect(() => {
    if (account && selectedCommission) {
      (async () => {
        if (selectedCommission) {
          const isCommissionMemberResult: boolean =
            await READ_DAO_CONTRACT.methods
              .isCommissionMembers(selectedCommission, account)
              .call();
          setisCommissionMember(isCommissionMemberResult);
        }
      })();
    }
  }, [selectedCommission, account]);

  const proposalValidation = useMemo(() => {
    if (type === "4" || type === "5") {
      return !(!isLeadership && !isOwner && !isCommissionMember);
    } else if (type === "1" || type === "2" || type === "3") {
      return isLeadership;
    }
    return true;
  }, [isLeadership, isOwner, isCommissionMember, type]);

  const createProposalHandler = async () => {
    if (loading) {
      return toast("Please wait...", customToastStyle);
    }
    if (!account) {
      return toast(
        "Please connect wallet to create proposal.",
        customToastStyle
      );
    }
    if (!(lopBalance > 0 || parseFloat(stakeAmount) > 0)) {
      return toast(
        "You have to own LOP token to create a new proposal.",
        customToastStyle
      );
    }
    if (!title) {
      return toast("Please enter title.", customToastStyle);
    }
    if (!description) {
      return toast("Please enter description.", customToastStyle);
    }
    if (!period) {
      return toast("Please input proposal period.", customToastStyle);
    }

    try {
      setLoading(true);
      const data = JSON.stringify({
        title,
        description,
        attachment: { file: fileName, type: fileType, url: fileUrl },
      });
      const metaDataUrl = await uploadFile(data);
      const writeDaoContract = await WRITE_DAO_CONTRACT();
      const _amount = Web3.utils.toWei(amount, "ether");
      await writeDaoContract.methods
        .createProposal(
          metaDataUrl,
          type,
          _amount,
          parseInt(selectedCommission || "0"),
          Math.floor(parseFloat(period) * 3600)
        )
        .send({
          from: account,
          gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
        });

      toast("Proposal created successfully.", customToastStyle);

      nav("/dao/proposals");
    } catch (e) {
      return toast(
        "There is an error to intract smart contract",
        customToastStyle
      );
    } finally {
      setLoading(false);
    }
  };

  const fileChangeHadler = (event: any) => {
    if (!(event?.target?.files?.length > 0)) {
      return "";
    }
    const file = event.target.files[0];

    setLoadingFile(true);

    S3FileUpload.uploadFile(file, {
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: "us-east-1",
    })
      .then((data: any) => {
        setFileUrl(data.location);
        setFileName(file.name);
        setFileType(file.type);
        setLoadingFile(false);
      })
      .catch((err: any) => {
        setLoadingFile(false);
      });
  };

  const amountHandler = (e: any) => {
    if (type === "4") {
      // commission budget for rLOP
      const remain = parseFloat(rlopSupplyLimit) - parseFloat(rlopTotalSupply);
      if (e.target.value > remain) {
        return toast(
          "Commission rLOP budget should be less than available token amount.",
          customToastStyle
        );
      }
    }
    if (type === "5") {
      // commission budget for LOP
      const remain = parseFloat(lopDaoPoolLimit) - parseFloat(lopDaoPoolused);
      if (e.target.value > remain) {
        return toast(
          "Commission LOP budget should be less than available token amount.",
          customToastStyle
        );
      }
    }
    setAmount(e.target.value);
  };
  return (
    <Form>
      <div
        style={{ position: "absolute", right: 40, top: 30 }}
        onClick={() => nav("/dao/proposals")}
      >
        <AiOutlineClose fontSize={24} />
      </div>

      <Alert variant={"success"}>
        Proposals can be made to:
        <li>Request product improvements</li>
        {isLeadership && <li>Increase the total supply of rLOP</li>}
        {isLeadership && (
          <li>Increase the LOP of tokens that can be sold to investors</li>
        )}
        {isLeadership && (
          <li>Increase the number of tokens allocated to the DAO</li>
        )}
        {(isLeadership || isCommissionMember) && (
          <li>Submit a LOP or rLOP Budget as a Commission head</li>
        )}
        {/* <b>
          Only allow the items to show up that the user has permission to make a
          proposal for.
        </b> */}
      </Alert>
      {isLeadership && (
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Supply info</Card.Title>
            <Card.Text>
              <Stack direction="horizontal" gap={3}>
                <div className="p-2">
                  <b>Total LOP:</b> {getLocaleString(lopSupplyLimit)}(LOP)
                </div>
                {/* <div className="p-2">
                  <b>LOP Total Supply:</b> {getLocaleString(lopTotalSupply)}(LOP)
                </div> */}
                <div className="p-2">
                  <b>Authorized rLOP Supply:</b>{" "}
                  {getLocaleString(rlopSupplyLimit)}(rLOP)
                </div>
                <div className="p-2">
                  <b>Authorized rLOP Supply Used:</b>{" "}
                  {getLocaleString(rlopTotalSupply)}(rLOP)
                </div>
              </Stack>
            </Card.Text>
            <Card.Text>
              <Stack direction="horizontal" gap={3}>
                <div className="p-2">
                  <b>Total LOP Allocated to the DAO:</b>{" "}
                  {getLocaleString(lopDaoPoolLimit)}
                  (LOP)
                </div>
                <div className="p-2">
                  <b>LOP Dao Pool Used:</b> {getLocaleString(lopDaoPoolused)}
                  (LOP)
                </div>
              </Stack>
            </Card.Text>
          </Card.Body>
        </Card>
      )}

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Proposal</Form.Label>
        <Form.Select
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <option>Please select option</option>
          <option value="0">Basic proposal</option>
          {isLeadership && <option value="1">rLOP Total Supply</option>}
          {isLeadership && <option value="2">LOP Investor Pool Supply</option>}
          {isLeadership && <option value="3">LOP DAO Pool Supply</option>}
          {(isLeadership || isCommissionMember) && (
            <option value="4">Commission Budget For rLOP</option>
          )}
          {(isLeadership || isCommissionMember) && (
            <option value="5">Commission Budget For LOP</option>
          )}
        </Form.Select>
      </Form.Group>

      {(type === "4" || type === "5") && (
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Commission</Form.Label>
          <Form.Select
            value={selectedCommission}
            onChange={(e) => {
              setSelectedCommission(e.target.value);
            }}
          >
            <option>Please select option</option>
            {commissions.map((item: any, index) => (
              <option key={index} value={item?.id}>
                Name: {item?.name}, Approved Budget: {item?.approvedBudget}
                (LOP/rLOP), Used Budget: {item?.usedBudget}(LOP/rLOP)
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}

      {type !== "0" && (
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>
            Amount
            {type === "1" && `(rLOP) ($${amountToUsd})`}
            {type === "2" && `(LOP) ($${amountToUsd})`}
            {type === "3" && `(LOP) ($${amountToUsd})`}
            {type === "4" &&
              `(Available: ${getLocaleString(
                parseFloat(rlopSupplyLimit) - parseFloat(rlopTotalSupply)
              )} rLOP)`}
            {type === "5" &&
              `(Available: ${getLocaleString(
                parseFloat(lopDaoPoolLimit) - parseFloat(lopDaoPoolused)
              )} LOP)`}
          </Form.Label>
          <Form.Control type="text" value={amount} onChange={amountHandler} />
        </Form.Group>
      )}

      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Proposal Period (hour)</Form.Label>
        <Form.Control
          type="text"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload File</Form.Label>
        {loadingFile && (
          <Form.Group>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Form.Group>
        )}
        <Form.Control
          type="file"
          onChange={fileChangeHadler}
          style={{ display: loadingFile ? "none" : "block" }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Button
          variant="primary"
          onClick={createProposalHandler}
          disabled={!proposalValidation || loading}
        >
          Create Proposal
        </Button>
      </Form.Group>
    </Form>
  );
}

export default Projects;
