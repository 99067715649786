import { useEffect } from "react";

import { store } from "../../store";
import { getTokenHolders } from "../../apis/getTokenHolder";
import { ERC20LOP_ADDRESS } from "../../configs/addresses";
import { getLocaleString } from "../../utils";

import DataTable from "react-data-table-component";

function Members(props: any) {
  const [erc20LHolders, setErc20LHolders] = store.useState("erc20LHolders");

  useEffect(() => {
    (async () => {
      setErc20LHolders(await getTokenHolders(ERC20LOP_ADDRESS));
    })();
  }, []);

  return (
    <div>
      <DataTable
        columns={[
          {
            name: "No",
            selector: (row: any) => row?.index,
            sortable: true,
          },
          {
            name: "Member",
            selector: (row: any) => row?.holderAddress,
            sortable: true,
          },
          // {
          //   name: 'Balance',
          //   selector: (row: any) => getLocaleString(row?.balance) + " LOP",
          //   sortable: true,
          // },
        ]}
        data={erc20LHolders as any}
      />
    </div>
  );
}

export default Members;
