import { useMemo } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

import Layout from "../../layout";
import { BgColor } from "../../App";

import Members from "./members";
import Projects from "./proposals";
import CreateProposal from "./CreateProposal";
import CreateVest from "./CreateVest";
import Vests from "./vests";
import Swaps from "./swaps";
import Stake from "./stake";
import Commissions from "./commissions";
import "./dao.scss";
import CreateCommission from "./CreateCommission";

function Dao(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const page_title = useMemo(() => {
    if (!params.sub_url) {
      return
    }

    switch (params.sub_url) {
      case "create_proposal":
        return `Create Proposal`;
      case "create_commission":
        return `Create Commission`;
      case "create_vest":
        return `Create Task`;
      case "swaps":
        return `DAO Swap Lists`;
      case "vests":
        return `Work Proposals`;
      case "stake":
        return `Stake LOP token`;
      default:
        return `DAO ${params.sub_url?.[0].toUpperCase() + params.sub_url.slice(1)}`;
    }
  }, [params.sub_url]);

  return (
    <Layout>
      <div className="page-container" style={{ background: BgColor.YELLOW }}>
        <div className="card-content p-4">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="font-24 inter-bold">{page_title}</h2>
            {
              params.sub_url === "members" &&
              <Button variant="primary" onClick={() => {
                navigate("/orgchart");
              }}>
                View Organization
              </Button>
            }
          </div>
          <div className="dao-content mt-4">
            {params.sub_url === "members" && <Members />}
            {params.sub_url === "proposals" && <Projects />}
            {params.sub_url === "create_proposal" && <CreateProposal />}
            {params.sub_url === "create_vest" && <CreateVest />}
            {params.sub_url === "create_commission" && <CreateCommission />}
            {params.sub_url === "vests" && <Vests />}
            {params.sub_url === "swaps" && <Swaps />}
            {params.sub_url === "stake" && <Stake />}
            {params.sub_url === "commissions" && <Commissions />}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dao;
