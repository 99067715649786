import { useEffect, useState, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import { Button, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Countdown from "react-countdown";
import Web3 from "web3";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";

import ProposalModal from "../../components/ProposalModal";

import {
  READ_DAO_CONTRACT,
  WRITE_DAO_CONTRACT,
  READ_ERC20LOP_CONTRACT,
} from "../../configs/smart_contracts";
import { getAwsMetaData } from "../../utils/cloud";
import { getShortAddress, getLocaleString } from "../../utils";

import { BgColor } from "../../App";
import { store } from "../../store";
import { customToastStyle } from "../../configs/constants";
import { formatDate } from "../../utils/time";
import FileIcon from "../../assets/img/file.png";

import DataTable from "react-data-table-component";

function getPercent(a: any, b: any) {
  const percent = Math.floor((parseFloat(a) * 100) / parseFloat(b));
  if (percent > 100) {
    return 100;
  }
  if (!percent) {
    return 0;
  }
  return percent;
}

function Proposals(props: any) {
  const PROPOSAL_STATUS = ["NONE", "CREATED", "CANCELLED", "ACTIVATED"];
  const POROPOSAL_TYPES = [
    "Basic proposal",
    "rLOP total supply",
    "LOP investor pool supply",
    "LOP DAO Pool supply",
    "Commission Budget for rLOP",
    "Commission Budget for LOP",
  ];

  const PROPOSAL_STATUS_COLOR: any = {
    NONE: BgColor.GREY,
    CREATED: BgColor.GREEN,
    CANCELLED: BgColor.RED,
    ACTIVATED: BgColor.BLUE,
  };

  const nav = useNavigate();

  const [proposalIndex, setProposalIndex] = useState(0);
  const [proposals, setProposals]: [proposals: any, setProposals: Function] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [proposalLoading, setProposalLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [stakedAmount, setstakedAmount] = useState("0");
  const [proposalInfo, setProposalInfo]: [
    proposalInfo: any,
    setProposalInfo: any
  ] = useState({
    creator: "",
    wallet: "",
    title: "",
    description: "",
    createdAt: "",
    remain: "",
    status: "",
    proposalType: "",
    commissionId: "",
  });
  const [lopInvestmentPoolLimit, setlopInvestmentPoolLimit] = useState("");
  const [lopInvestmentPoolused, setlopInvestmentPoolused] = useState("");
  const [lopSupplyLimit, setlopSupplyLimit] = useState("");
  const [rlopSupplyLimit, setrlopSupplyLimit] = useState("");
  const [rlopTotalSupply, setrlopTotalSupply] = useState("");
  const [lopDaoPoolLimit, setlopDaoPoolLimit] = useState("");
  const [lopDaoPoolused, setlopDaoPoolused] = useState("");
  const [isLeadership, setIsLeadership] = useState(false);

  const [account] = store.useState("account");
  const [erc20LopTotalSupply, setErc20LopTotalSupply] = store.useState(
    "erc20LopTotalSupply"
  );
  const [lBalance, setLBalance] = store.useState("lBalance");

  const viewLBalance = useMemo(() => {
    return Web3.utils.fromWei(lBalance?.toString() || "0", "ether");
  }, [lBalance]);

  const voteEnabled: boolean = useMemo(() => {
    return parseFloat(stakedAmount) > 0;
  }, [stakedAmount]);

  const _getInitialData = async () => {
    const _proposalIndex: any = await READ_DAO_CONTRACT.methods
      .proposalIndex()
      .call();
    setProposalIndex(parseInt(_proposalIndex) || 0);
    if (account) {
      const _stakedAmount = await READ_DAO_CONTRACT.methods
        .stakeInfo(account)
        .call();
      setstakedAmount(
        Web3.utils.fromWei(_stakedAmount?.toString() || "", "ether")
      );
    }
    const _lopInvestmentPoolLimit = await READ_DAO_CONTRACT.methods
      .lopInvestmentPoolLimit()
      .call();
    setlopInvestmentPoolLimit(
      Web3.utils.fromWei(_lopInvestmentPoolLimit?.toString() || "", "ether")
    );
    const _lopInvestmentPoolused = await READ_DAO_CONTRACT.methods
      .lopInvestmentPoolused()
      .call();
    setlopInvestmentPoolused(
      Web3.utils.fromWei(_lopInvestmentPoolused?.toString() || "", "ether")
    );
    const _lopSupplyLimit = await READ_DAO_CONTRACT.methods
      .lopTotalSupply()
      .call();
    setlopSupplyLimit(
      Web3.utils.fromWei(_lopSupplyLimit?.toString() || "", "ether")
    );

    const _rlopSupplyLimit = await READ_DAO_CONTRACT.methods
      .rlopSupplyLimit()
      .call();
    setrlopSupplyLimit(
      Web3.utils.fromWei(_rlopSupplyLimit?.toString() || "", "ether")
    );
    const _rlopTotalSupply = await READ_DAO_CONTRACT.methods
      .rlopSupplyUsed()
      .call();
    setrlopTotalSupply(
      Web3.utils.fromWei(_rlopTotalSupply?.toString() || "", "ether")
    );
    const _lopDaoPoolLimit = await READ_DAO_CONTRACT.methods
      .lopDaoPoolLimit()
      .call();
    setlopDaoPoolLimit(
      Web3.utils.fromWei(_lopDaoPoolLimit?.toString() || "", "ether")
    );
    const _lopDaoPoolused = await READ_DAO_CONTRACT.methods
      .lopDaoPoolused()
      .call();
    setlopDaoPoolused(
      Web3.utils.fromWei(_lopDaoPoolused?.toString() || "", "ether")
    );
  };

  const _proposalsInfo = async () => {
    if (proposals.length === 0) {
      setProposalLoading(true);
    }
    const _proposals = [];

    const blockTimestamp: any = await READ_DAO_CONTRACT.methods
      .getBlockTimestamp()
      .call();

    const _erc20TtoalSupply = await READ_DAO_CONTRACT.methods
      .lopTotalSupply()
      .call();
    const viewErc20LopTotalSupply = parseFloat(
      Web3.utils.fromWei(_erc20TtoalSupply?.toString() || "0", "ether")
    );
    setErc20LopTotalSupply(_erc20TtoalSupply?.toString() || "0");

    const _proposalIndex: any = await READ_DAO_CONTRACT.methods
      .proposalIndex()
      .call();

    for (let i = 0; i < _proposalIndex; i++) {
      const proposalInfo: any = await READ_DAO_CONTRACT.methods
        .getProposal(i)
        .call();

      const owner = proposalInfo?.owner;
      const status = PROPOSAL_STATUS[parseInt(proposalInfo?.status)];
      const createdAtTimestamp = proposalInfo?.createdAt;
      const proposalPeriod = proposalInfo?.period;
      const endedAtTimestamp = createdAtTimestamp + proposalPeriod;
      const voteYes = parseInt(proposalInfo?.voteYes);
      const voteYesAmount = parseFloat(
        Web3.utils.fromWei(proposalInfo?.voteYesAmount || "0", "ether")
      );
      const voteNo = parseInt(proposalInfo?.voteNo);
      const voteNoAmount = parseFloat(
        Web3.utils.fromWei(proposalInfo?.voteNoAmount || "0", "ether")
      );

      let remain: any = endedAtTimestamp - blockTimestamp;
      remain = parseInt(remain);

      const createdAt = formatDate(
        new Date(parseInt(createdAtTimestamp) * 1000)
      );

      const metaDataUrl = proposalInfo?.metadata;
      const metadata: any = await getAwsMetaData(metaDataUrl);
      const file = metadata?.attachment?.file;
      const file_url = metadata?.attachment?.url;

      const title = metadata?.title;
      const description = metadata?.description;
      const proposalType =
        POROPOSAL_TYPES[parseInt(proposalInfo?.proposalType)];
      const commissionId = parseInt(proposalInfo?.commissionId);
      const proposalAmount = Web3.utils.fromWei(
        proposalInfo?.proposalAmount,
        "ether"
      );

      let voteYesPercent = 0;
      if (parseInt(proposalInfo?.proposalType) === 0) {
        voteYesPercent = getPercent(
          voteYesAmount,
          voteYesAmount + voteNoAmount
        );
      } else {
        voteYesPercent = getPercent(voteYesAmount, viewErc20LopTotalSupply);
      }

      let voteNoPercent = 0;
      if (parseInt(proposalInfo?.proposalType) === 0) {
        voteNoPercent = getPercent(voteNoAmount, voteYesAmount + voteNoAmount);
      } else {
        voteNoPercent = getPercent(voteNoAmount, viewErc20LopTotalSupply);
      }
      console.log(voteYesPercent, voteNoPercent);

      let isVoted = false;

      if (account) {
        const votingInfo: any = await READ_DAO_CONTRACT.methods
          .votingList(account, i)
          .call();
        isVoted = votingInfo?.isVoted;
      }

      const info: any = {
        owner,
        status,
        title,
        description,
        isVoted,
        createdAt,
        remain,
        voteYes,
        voteYesAmount,
        voteNo,
        voteNoAmount,
        voteYesPercent,
        voteNoPercent,
        file,
        file_url,
        proposalType,
        commissionId,
        proposalAmount,
        index: _proposals.length,
      };

      _proposals.push(info);
    }

    if (proposals.length === 0) {
      setProposalLoading(false);
    }

    _proposals.sort((a, b) => {
      if (a.createdAt <= b.createdAt) return -1;
      return 1;
    });
    _proposals.sort((a, b) => {
      if (a.status === PROPOSAL_STATUS[3]) return -1;
      return 1;
    });
    _proposals.sort((a, b) => {
      if (a.status === PROPOSAL_STATUS[1]) return -1;
      return 1;
    });
    setProposals(_proposals);
  };

  const init = async () => {
    await _getInitialData();
    await _proposalsInfo();
  };

  useEffect(() => {
    init();
  }, [account]);

  useEffect(() => {
    if (account) {
      (async () => {
        setIsLeadership(
          await READ_DAO_CONTRACT.methods.isLeadership(account).call()
        );
      })();
    }
  }, [account]);

  useEffect(() => {
    (async () => {
      if (account) {
        const _lBalance = await READ_ERC20LOP_CONTRACT.methods
          .balanceOf(account)
          .call();
        setLBalance(_lBalance?.toString() || "0");
      }
    })();
  }, [account, setLBalance]);

  useEffect(() => {
    _proposalsInfo();
  }, [proposalIndex]);

  const voteYesHandler = async (index: number) => {
    if (!account) {
      return toast("Please login to vote", customToastStyle);
    }
    if (!(parseFloat(stakedAmount) > 0)) {
      return toast("Please stake token to vote", customToastStyle);
    }
    setLoading(true);
    try {
      const writeDaoContract = await WRITE_DAO_CONTRACT();
      await writeDaoContract.methods.voteYes(index).send({
        from: account,
        gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
      });
    } catch (e: any) {
      return toast(e.message, customToastStyle);
    } finally {
      setLoading(false);
    }

    _getInitialData();
    _proposalsInfo();
  };

  const voteNoHandler = async (index: number) => {
    if (!account) {
      return toast("Please login to vote", customToastStyle);
    }
    if (!(parseFloat(stakedAmount) > 0)) {
      return toast("Please stake token to vote", customToastStyle);
    }
    setLoading(true);
    try {
      const writeDaoContract = await WRITE_DAO_CONTRACT();
      await writeDaoContract.methods.voteNo(index).send({
        from: account,
        gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
      });
    } catch (e: any) {
      return toast(e.message, customToastStyle);
    } finally {
      setLoading(false);
    }

    _getInitialData();
    _proposalsInfo();
  };

  const executeHandler = async (index: number) => {
    if (!account) {
      return toast("Please login to execute", customToastStyle);
    }
    if (!(parseFloat(stakedAmount) > 0)) {
      return toast("Please stake token to vote", customToastStyle);
    }
    setLoading(true);
    try {
      const writeDaoContract = await WRITE_DAO_CONTRACT();
      await writeDaoContract.methods.execute(index).send({
        from: account,
        gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
      });
    } catch (e: any) {
      return toast(e.message, customToastStyle);
    } finally {
      setLoading(false);
    }

    _getInitialData();
    _proposalsInfo();
  };

  const createProposalHandler = () => {
    nav("/dao/create_proposal");
  };

  const onRowClicked = (item: any, event: any) => {
    setProposalInfo({
      creator: item?.owner,
      wallet: getShortAddress(item?.owner),
      title: item?.title,
      description: item?.description,
      proposalAmount: item?.proposalAmount,
      createdAt: item?.createdAt,
      remain: item?.remain,
      status: item?.status,
      proposalType: item?.proposalType,
      commissionId: item?.commissionId,
      voteYesAmount: item?.voteYesAmount,
      voteNoAmount: item?.voteNoAmount,
      voteYesPercent: item?.voteYesPercent,
      voteNoPercent: item?.voteNoPercent,
      file: item?.file,
      file_url: item?.file_url,
    });
    setShow(true);
  };

  return (
    <div>
      {isLeadership && (
        <Alert variant="info">
          <Card.Title>Supply info</Card.Title>
          <Card.Text>
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <b>Total LOP:</b> {getLocaleString(lopSupplyLimit)}(LOP)
              </div>
              {/* <div className="p-2">
                  <b>LOP Total Supply:</b> {getLocaleString(lopTotalSupply)}(LOP)
                </div> */}
              <div className="p-2">
                <b>Authorized rLOP Supply:</b>{" "}
                {getLocaleString(rlopSupplyLimit)}
                (rLOP)
              </div>
              <div className="p-2">
                <b>Authorized rLOP Supply Used:</b>{" "}
                {getLocaleString(rlopTotalSupply)}(rLOP)
              </div>
            </Stack>
          </Card.Text>
          <Card.Text>
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <b>Total LOP Allocated to the DAO:</b>{" "}
                {getLocaleString(lopDaoPoolLimit)}
                (LOP)
              </div>
              <div className="p-2">
                <b>LOP Dao Pool Used:</b> {getLocaleString(lopDaoPoolused)}(LOP)
              </div>
              <div className="p-2">
                <b>Investor Supply:</b>{" "}
                {getLocaleString(lopInvestmentPoolLimit)}
                (LOP)
              </div>
              <div className="p-2">
                <b>Investor Supply Used:</b>{" "}
                {getLocaleString(lopInvestmentPoolused)}(LOP)
              </div>
            </Stack>
          </Card.Text>
        </Alert>
      )}
      {!voteEnabled && (
        <Alert variant={"danger"}>
          You have to stake LOP token to vote proposals.
        </Alert>
      )}
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          flexWrap: "nowrap",
          marginBottom: 20,
        }}
      >
        <Button onClick={createProposalHandler}>Create Proposal</Button>
      </div>
      <ProposalModal
        show={show}
        handleClose={() => setShow(false)}
        proposalInfo={proposalInfo}
      />

      <DataTable
        columns={[
          {
            name: "Proposal Type",
            selector: (item: any) => item?.proposalType as any,
            sortable: true,
          },
          {
            name: "Creator",
            selector: (item: any) => getShortAddress(item?.owner),
            sortable: true,
          },
          {
            name: "Title",
            selector: (item: any) => item?.title,
            sortable: true,
          },
          {
            name: "Description",
            selector: (item: any) => item?.description as any,
            sortable: true,
          },
          {
            name: "Amount",
            selector: (item: any) =>
              item?.proposalType === POROPOSAL_TYPES[0]
                ? item?.proposalAmount
                : item?.proposalType === POROPOSAL_TYPES[1] ||
                  item?.proposalType === POROPOSAL_TYPES[4]
                ? `${item?.proposalAmount} (rLOP)`
                : `${item?.proposalAmount} (LOP)`,
            sortable: true,
          },
          {
            name: "File",
            selector: (item: any) =>
              item.file ? (
                <div>
                  <img src={FileIcon} alt="file" style={{ width: 30 }} />
                  <a href={item.file_url} target="_blank" rel="noreferrer">
                    {item.file}
                  </a>
                </div>
              ) : (
                (null as any)
              ),
            sortable: false,
          },
          {
            name: "Created At",
            selector: (item: any) => item?.createdAt,
            sortable: true,
          },
          {
            name: "Remain",
            selector: (item: any) =>
              (
                <Countdown
                  date={Date.now() + item?.remain * 1000}
                  renderer={({ days, hours, minutes, seconds, completed }) => {
                    if (completed) {
                      return <span>Expired</span>;
                    } else {
                      return (
                        <span>
                          {days}d {hours}h {minutes}m {seconds}s
                        </span>
                      );
                    }
                  }}
                />
              ) as any,
            sortable: false,
          },
          {
            name: "Vote(Yes/No)",
            selector: (item: any) =>
              `${getLocaleString(item?.voteYesAmount)} / ${getLocaleString(
                item?.voteNoAmount
              )}`,
            sortable: true,
          },
          {
            name: "Vote Percent(Yes/No)",
            selector: (item: any) =>
              `${item?.voteYesPercent}% / ${item?.voteNoPercent}%`,
            sortable: true,
          },
          {
            name: "Proposal Status",
            selector: (item: any) => item?.status,
            sortable: true,
          },
          {
            name: "Action",
            selector: (item: any) =>
              (
                <ButtonGroup size="sm">
                  {item?.status === "CREATED" && !account && (
                    <Button variant="light" disabled size="sm">
                      Require Login
                    </Button>
                  )}
                  {item?.status === "CREATED" &&
                    !!account &&
                    !item?.isVoted &&
                    item?.remain > 0 &&
                    voteEnabled && (
                      <Button
                        variant="primary"
                        onClick={() => voteYesHandler(item?.index)}
                        disabled={loading || !voteEnabled}
                        size="sm"
                      >
                        Yes
                      </Button>
                    )}
                  {item?.status === "CREATED" &&
                    !!account &&
                    !item?.isVoted &&
                    item?.remain > 0 &&
                    voteEnabled && (
                      <Button
                        variant="secondary"
                        onClick={() => voteNoHandler(item?.index)}
                        disabled={loading || !voteEnabled}
                        size="sm"
                      >
                        No
                      </Button>
                    )}
                  {
                    //item?.proposalType !== "Basic proposal" &&
                    item?.status === "CREATED" &&
                      (account === item?.owner || isLeadership) &&
                      voteEnabled &&
                      item?.remain <= 0 &&
                      item?.voteYesPercent >= 50 && (
                        <Button
                          variant="success"
                          onClick={() => executeHandler(item?.index)}
                          disabled={loading}
                          size="sm"
                        >
                          Execute
                        </Button>
                      )
                  }
                </ButtonGroup>
              ) as any,
            sortable: false,
          },
        ]}
        data={proposals as any}
        onRowClicked={onRowClicked}
        progressPending={proposalLoading}
        pagination
      />
    </div>
  );
}

export default Proposals;
