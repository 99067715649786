import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { BgColor } from "../App";

const defaultColor = {
  dashboard: "",
  token: "",
  option: "",
  dao: "",
};

function Menu() {
  const location = useLocation();

  const bgColor = useMemo(() => {
    let updatedColor;

    switch (location.pathname) {
      case "/dashboard":
        updatedColor = { ...defaultColor, dashboard: BgColor.RED };
        break;
      case "/token":
        updatedColor = { ...defaultColor, token: BgColor.YELLOW };
        break;
      case "/tokenoption":
        updatedColor = { ...defaultColor, option: BgColor.BLUE };
        break;
      default:
        updatedColor = { ...defaultColor, dao: BgColor.YELLOW };
        break;
    }

    return updatedColor;
  }, [location.pathname]);

  return (
    <ul className="menu">
      <li style={{ background: bgColor.dashboard }}>
        <Link to="/dashboard">Dashboard</Link>
      </li>
      <li style={{ background: bgColor.token }}>
        <Link to="/token">LOP Token</Link>
      </li>
      <li style={{ background: bgColor.option }}>
        <Link to="/tokenoption">rLOP Option</Link>
      </li>
      <li style={{ background: bgColor.dao }} className="over-menu">
        <label>DAO</label>
        <div className="sub-menu">
          <div className="devider"></div>
          <div className="sub-link">
            <Link to="/dao/members">Members</Link>
            <Link to="/dao/commissions">Commissions</Link>
            <Link to="/dao/proposals">Proposals</Link>
            <Link to="/dao/vests">Work Proposals</Link>
            <Link to="/dao/stake">Staking</Link>
            <Link to="/dao/swaps" className="border-0">Swaps</Link>
            {/* <Link to="/dao/votes" className="border-0">
              Votes
            </Link> */}
          </div>
        </div>
      </li>
    </ul>
  );
}

export default Menu;
