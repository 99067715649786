import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import Countdown from "react-countdown";

import MakeBid from "../../components/MakeBid";
import ViewBids from "../../components/ViewBids";
import ReleaseBid from "../../components/ReleaseBid";
import WorkProposalModal from "../../components/WorkProposalModal";

import { store } from "../../store";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";
import FileIcon from "../../assets/img/file.png";

import { formatDate } from "../../utils/time";
import { getTasks, deleteProposal, getTime, getOrg } from "../../apis/vestApi";
import {
  READ_DAO_CONTRACT,
  READ_ERC20LOP_CONTRACT,
  READ_ERC20_R_LOP_CONTRACT,
  WRITE_DAO_CONTRACT,
} from "../../configs/smart_contracts";

function Vests(props: any) {
  const nav = useNavigate();

  const [show, setShow] = useState(false);
  const [proposalInfo, setProposalInfo] = useState({});
  const [showMakeBid, setShowMakeBid] = useState(false);
  const [showViewBids, setShowViewBids] = useState(false);
  const [showReleaseBid, setShowReleaseBid] = useState(false);
  const [selectedProposalId, setSelectedProposalId] = useState("");
  const [activatedProposalId, setActivatedProposalId] = useState("");
  const [activeAddress, setActiveAddress] = useState("");
  const [selectedTask, setSelectedTask]: [
    selectedTask: any,
    setSelectedTask: Function
  ] = useState({});
  const [tasks, settasks]: [tasks: any, settasks: Function] = useState([]);
  const [searchCommission, setSearchCommission] = useState("");
  const [proposalLoading, setProposalLoading] = useState(false);
  const [isLeadership, setIsLeadership] = useState(false);

  const [account] = store.useState("account");

  const searchTasks = useMemo(() => {
    if (!searchCommission) {
      return tasks;
    }
    return tasks.filter(
      (item: any) =>
        item.commissionName &&
        item.commissionName
          .toLowerCase()
          .includes(searchCommission.toLowerCase())
    );
  }, [tasks, searchCommission]);

  const init = async () => {
    try {
      if (tasks.length === 0) {
        setProposalLoading(true);
      }

      const _tasks: any = await getTasks();
      const serverTime = await getTime();
      const proposalPeriod = 7 * 24 * 60 * 60 * 1000; // 7 days
      for (let i = 0; i < _tasks.length; i++) {
        const endedAtTimestamp = +_tasks[i]?.createdAt + proposalPeriod;
        let remain: any = endedAtTimestamp - serverTime;
        remain = parseInt(remain);

        const createdAt = formatDate(new Date(+_tasks[i]?.createdAt));

        _tasks[i].createdAt = createdAt;
        _tasks[i].remain = remain;

        if (_tasks[i]?.proposalId) {
          const vesting: any = await READ_DAO_CONTRACT.methods
            .vestingList(_tasks[i]?.proposalId)
            .call();
          const amount = Web3.utils.fromWei(vesting?.amount, "ether");
          const claimed = Web3.utils.fromWei(vesting?.claimed, "ether");
          _tasks[i].amount = amount;
          _tasks[i].claimed = claimed;
          _tasks[i].status = amount !== claimed;
          _tasks[i].remain = 0;
        }
        const commission: any = await READ_DAO_CONTRACT.methods
          .commissions(_tasks[i]?.commissionId)
          .call();
        _tasks[i].approvedBudget = Web3.utils.fromWei(
          commission?.approvedBudget,
          "ether"
        );
        _tasks[i].approvedBudgetLOP = Web3.utils.fromWei(
          commission?.approvedBudgetLOP,
          "ether"
        );
        _tasks[i].usedBudget = Web3.utils.fromWei(
          commission?.usedBudget,
          "ether"
        );
        _tasks[i].usedBudgetLOP = Web3.utils.fromWei(
          commission?.usedBudgetLOP,
          "ether"
        );
      }

      _tasks.sort((a: any, b: any) => {
        if (!a.proposalId) return -1;
        if (!b.proposalId) return 1;
        if (a.createdAt >= b.createdAt) return -1;
        return 1;
      });

      settasks(_tasks);
      if (tasks.length === 0) {
        setTimeout(() => {
          setProposalLoading(false);
        }, 6000);
      }
    } catch (e) {}
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (account) {
      (async () => {
        setIsLeadership(
          await READ_DAO_CONTRACT.methods.isLeadership(account).call()
        );
      })();
    } else {
      setIsLeadership(false);
    }
  }, [account]);

  const createVestHandler = () => {
    nav("/dao/create_vest");
  };

  const deleteProposalHandler = async (id: string) => {
    const confirm = window.confirm("Are you sure to delete this proposal");
    if (confirm) {
      const res = await deleteProposal({ id });
      console.log(res);
      if (res) {
        await init();
        toast("Deleted proposal successfully.", customToastSuccessStyle);
      }
    }
  };

  const cancelProposalHandler = async (id: string) => {
    console.log(id);
    try {
      const writeDaoContract = await WRITE_DAO_CONTRACT();
      await writeDaoContract.methods
        .cancelVest(parseInt(id))
        .send({ from: account });

      await init();
      toast("Cancel proposal successfully.", customToastSuccessStyle);
    } catch (e) {
      toast("Error to cancel proposal.", customToastStyle);
    }
  };

  const searchCommissionHandler = (e: any) => {
    setSearchCommission(e.target.value);
  };

  const onRowClicked = (item: any, event: any) => {
    setProposalInfo(item);
    setShow(true);
  };

  return (
    <div>
      <ViewBids
        show={showViewBids}
        handleClose={() => {
          setShowViewBids(false);
          setSelectedProposalId("");
          init();
          return false;
        }}
        proposalId={selectedProposalId}
        amount={selectedTask?.amount}
        tokenType={selectedTask?.tokenType}
        selectedCommission={selectedTask?.commissionId}
        selectedAccount={selectedTask?.creator}
      />

      <MakeBid
        show={showMakeBid}
        handleClose={() => {
          setShowMakeBid(false);
          setSelectedProposalId("");
          return false;
        }}
        selectedProposalId={selectedProposalId}
      />

      <ReleaseBid
        show={showReleaseBid}
        activeAddress={activeAddress}
        handleClose={() => {
          setShowReleaseBid(false);
          setActivatedProposalId("");
          setActiveAddress("");
          return false;
        }}
        selectedProposalId={activatedProposalId}
      />

      <WorkProposalModal
        show={show}
        handleClose={() => setShow(false)}
        proposalInfo={proposalInfo}
      />

      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexWrap: "nowrap",
          marginBottom: 20,
          height: 35,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Search by commission"
            value={searchCommission}
            onChange={(e) => {
              searchCommissionHandler(e);
            }}
          />
        </div>

        {isLeadership && (
          <Button onClick={createVestHandler}>Create Task</Button>
        )}
      </div>

      <DataTable
        columns={
          isLeadership
            ? [
                {
                  name: "Commission",
                  selector: (item: any) => item?.commissionName,
                  sortable: true,
                  maxWidth: "200px",
                  minWidth: "200px",
                },
                {
                  name: "LOP Approved",
                  selector: (item: any) => item?.approvedBudgetLOP,
                  sortable: true,
                  minWidth: "150px",
                },
                {
                  name: "LOP Used",
                  selector: (item: any) => item?.usedBudgetLOP,
                  sortable: true,
                  minWidth: "120px",
                },
                {
                  name: "rLOP Approved",
                  selector: (item: any) => item?.approvedBudget,
                  sortable: true,
                  minWidth: "150px",
                },
                {
                  name: "rLOP Used",
                  selector: (item: any) => item?.usedBudget,
                  sortable: true,
                  minWidth: "120px",
                },
                {
                  name: "Task Name",
                  selector: (item: any) => item?.taskName,
                  sortable: true,
                  wrap: true,
                  minWidth: "150px",
                },
                {
                  name: "Task Description",
                  selector: (item: any) => item?.taskDescription,
                  sortable: true,
                  wrap: true,
                  minWidth: "150px",
                },
                {
                  name: "Created Date",
                  selector: (item: any) => item?.createdAt,
                  sortable: true,
                  wrap: true,
                  minWidth: "170px",
                },
                {
                  name: "Remain",
                  selector: (item: any) =>
                    (
                      <Countdown
                        date={+Date.now() + item?.remain}
                        renderer={({
                          days,
                          hours,
                          minutes,
                          seconds,
                          completed,
                        }) => {
                          if (completed) {
                            return <span>Expired</span>;
                          } else {
                            return (
                              <span>
                                {days}d {hours}h {minutes}m {seconds}s
                              </span>
                            );
                          }
                        }}
                      />
                    ) as any,
                  sortable: false,
                },
                {
                  name: "Attachment",
                  selector: (item: any) =>
                    (item.fileUrl && (
                      <>
                        <img src={FileIcon} alt="file" style={{ width: 30 }} />
                        <a href={item.fileUrl} target="_blank" rel="noreferrer">
                          {item.fileName}
                        </a>
                      </>
                    )) as any,
                  sortable: false,
                },
                {
                  name: "Amount",
                  selector: (item: any) =>
                    `${item?.amount} ${
                      item?.tokenType === "false" ? "LOP" : "rLOP"
                    }`,
                  sortable: true,
                },
                {
                  name: "Status",
                  selector: (item: any) =>
                    `${
                      item?.claimed === undefined
                        ? "Open Bid"
                        : item?.amount === item?.claimed
                        ? "Released"
                        : "Releasing..."
                    }`,
                  sortable: true,
                },
                {
                  name: "Make Bid",
                  selector: (item: any) =>
                    (!item?.proposalId && (
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (!account) {
                            return toast(
                              "Please connect wallet to create bid.",
                              customToastSuccessStyle
                            );
                          }
                          setSelectedProposalId(item.id);
                          setShowMakeBid(true);
                        }}
                        disabled={item?.proposalId}
                        size="sm"
                      >
                        Make Bid
                      </Button>
                    )) as any,
                  sortable: false,
                  minWidth: "120px",
                },
                {
                  name: "View Bids",
                  selector: (item: any) =>
                    (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setSelectedProposalId(item.id);
                          setShowViewBids(true);
                          setSelectedTask(item);
                        }}
                        size="sm"
                        style={{ width: 90 }}
                      >
                        View Bids
                      </Button>
                    ) as any,
                  sortable: false,
                  minWidth: "90",
                },
                ...(isLeadership
                  ? [
                      {
                        name: "Release",
                        selector: (item: any) =>
                          (
                            <Button
                              variant={
                                item?.amount === item?.claimed
                                  ? "info"
                                  : "success"
                              }
                              disabled={!item?.proposalId}
                              onClick={() => {
                                setShowReleaseBid(true);
                                setActivatedProposalId(item.proposalId);
                                setActiveAddress(item.creator);
                                setSelectedTask(item);
                              }}
                              size="sm"
                              style={{ width: 90 }}
                            >
                              {item?.amount === item?.claimed
                                ? "Completed"
                                : "Release"}
                            </Button>
                          ) as any,
                        sortable: false,
                        minWidth: "90",
                      },
                    ]
                  : []),
                ...(isLeadership
                  ? [
                      {
                        name: "Cancel",
                        selector: (item: any) =>
                          (item?.proposalId && item?.status && (
                            <Button
                              variant="warning"
                              disabled={!(item?.proposalId && item?.status)}
                              onClick={() => {
                                cancelProposalHandler(item.proposalId);
                              }}
                              size="sm"
                            >
                              Cancel
                            </Button>
                          )) as any,
                        sortable: false,
                      },
                    ]
                  : []),
                ...(isLeadership
                  ? [
                      {
                        name: "Delete",
                        selector: (item: any) =>
                          (!item?.proposalId && (
                            <Button
                              variant="danger"
                              disabled={item?.proposalId}
                              onClick={() => {
                                deleteProposalHandler(item?.id);
                              }}
                              size="sm"
                            >
                              Delete
                            </Button>
                          )) as any,
                        sortable: false,
                      },
                    ]
                  : []),
              ]
            : [
                {
                  name: "Commission",
                  selector: (item: any) => item?.commissionName,
                  sortable: true,
                  maxWidth: "200px",
                  minWidth: "200px",
                },
                {
                  name: "Task Name",
                  selector: (item: any) => item?.taskName,
                  sortable: true,
                  wrap: true,
                  minWidth: "150px",
                },
                {
                  name: "Task Description",
                  selector: (item: any) => item?.taskDescription,
                  sortable: true,
                  wrap: true,
                  minWidth: "150px",
                },
                {
                  name: "Created Date",
                  selector: (item: any) => item?.createdAt,
                  sortable: true,
                  wrap: true,
                  minWidth: "170px",
                },
                {
                  name: "Remain",
                  selector: (item: any) =>
                    (
                      <Countdown
                        date={+Date.now() + item?.remain}
                        renderer={({
                          days,
                          hours,
                          minutes,
                          seconds,
                          completed,
                        }) => {
                          if (completed) {
                            return <span>Expired</span>;
                          } else {
                            return (
                              <span>
                                {days}d {hours}h {minutes}m {seconds}s
                              </span>
                            );
                          }
                        }}
                      />
                    ) as any,
                  sortable: false,
                },
                {
                  name: "Attachment",
                  selector: (item: any) =>
                    (item.fileUrl && (
                      <>
                        <img src={FileIcon} alt="file" style={{ width: 30 }} />
                        <a href={item.fileUrl} target="_blank" rel="noreferrer">
                          {item.fileName}
                        </a>
                      </>
                    )) as any,
                  sortable: false,
                },
                {
                  name: "Amount",
                  selector: (item: any) =>
                    `${item?.amount} ${
                      item?.tokenType === "false" ? "LOP" : "rLOP"
                    }`,
                  sortable: true,
                },
                {
                  name: "Status",
                  selector: (item: any) =>
                    `${
                      item?.claimed === undefined
                        ? "Open Bid"
                        : item?.amount === item?.claimed
                        ? "Released"
                        : "Releasing..."
                    }`,
                  sortable: true,
                },
                {
                  name: "Make Bid",
                  selector: (item: any) =>
                    (!item?.proposalId && (
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (!account) {
                            return toast(
                              "Please connect wallet to create bid.",
                              customToastSuccessStyle
                            );
                          }
                          setSelectedProposalId(item.id);
                          setShowMakeBid(true);
                        }}
                        disabled={item?.proposalId}
                        size="sm"
                      >
                        Make Bid
                      </Button>
                    )) as any,
                  sortable: false,
                  minWidth: "120px",
                },
                {
                  name: "View Bids",
                  selector: (item: any) =>
                    (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setSelectedProposalId(item.id);
                          setShowViewBids(true);
                          setSelectedTask(item);
                        }}
                        size="sm"
                        style={{ width: 90 }}
                      >
                        View Bids
                      </Button>
                    ) as any,
                  sortable: false,
                  minWidth: "90",
                },
                ...(isLeadership
                  ? [
                      {
                        name: "Release",
                        selector: (item: any) =>
                          (
                            <Button
                              variant={
                                item?.amount === item?.claimed
                                  ? "info"
                                  : "success"
                              }
                              disabled={!item?.proposalId}
                              onClick={() => {
                                setShowReleaseBid(true);
                                setActivatedProposalId(item.proposalId);
                                setActiveAddress(item.creator);
                                setSelectedTask(item);
                              }}
                              size="sm"
                              style={{ width: 90 }}
                            >
                              {item?.amount === item?.claimed
                                ? "Completed"
                                : "Release"}
                            </Button>
                          ) as any,
                        sortable: false,
                        minWidth: "90",
                      },
                    ]
                  : []),
                ...(isLeadership
                  ? [
                      {
                        name: "Cancel",
                        selector: (item: any) =>
                          (item?.proposalId && item?.status && (
                            <Button
                              variant="warning"
                              disabled={!(item?.proposalId && item?.status)}
                              onClick={() => {
                                cancelProposalHandler(item.proposalId);
                              }}
                              size="sm"
                            >
                              Cancel
                            </Button>
                          )) as any,
                        sortable: false,
                      },
                    ]
                  : []),
                ...(isLeadership
                  ? [
                      {
                        name: "Delete",
                        selector: (item: any) =>
                          (!item?.proposalId && (
                            <Button
                              variant="danger"
                              disabled={item?.proposalId}
                              onClick={() => {
                                deleteProposalHandler(item?.id);
                              }}
                              size="sm"
                            >
                              Delete
                            </Button>
                          )) as any,
                        sortable: false,
                      },
                    ]
                  : []),
              ]
        }
        data={searchTasks as any}
        progressPending={proposalLoading}
        onRowClicked={onRowClicked}
        pagination
      />
    </div>
  );
}

export default Vests;
