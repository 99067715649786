import { useEffect, useMemo, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";

import Layout from "../layout";
import { BgColor } from "../App";
import { store } from "../store";
import { getShortAddress, getLocaleString, getHttpWeb3 } from "../utils";
import { getTokenHolders } from "../apis/getTokenHolder";
import { USDC_DECIMALS } from "../configs/constants";

import {
  READ_DAO_CONTRACT,
  READ_ERC20LOP_CONTRACT,
  READ_ERC20_R_LOP_CONTRACT,
  READ_USDC_CONTRACT,
} from "../configs/smart_contracts";
import {
  ERC20LOP_ADDRESS,
  ERC20RLOP_ADDRESS,
  DAO_ADDRESS,
} from "../configs/addresses";
import { CHAIN_BLOCK_EXPLORER_URLS } from "../configs/constants";

import EtherscanImg from "../assets/img/etherscan.png";
import TokenlogoImg from "../assets/img/token-logo.png";
import TokenlogoImg1 from "../assets/img/token-logo1.png";
import UsdclogoImg from "../assets/img/usdc-logo.png";
import MaticlogoImg from "../assets/img/matic-logo.png";
import BgImg from "../assets/img/bg.png";

function Dashboard(props: any) {
  const nav = useNavigate();

  const [account] = store.useState("account");
  const [balance, setBalance] = store.useState("balance");
  const [lopDaoPoolLimit, setlopDaoPoolLimit] =
    store.useState("lopDaoPoolLimit");
  const [rBalance, setRBalance] = store.useState("rBalance");
  const [lBalance, setLBalance] = store.useState("lBalance");
  const [usdcBalance, setUsdcBalance] = store.useState("usdcBalance");
  const [erc20LopTotalSupply, setErc20LopTotalSupply] = store.useState(
    "erc20LopTotalSupply"
  );
  const [erc20LopPrice, setErc20LopPrice] = store.useState("erc20LopPrice");
  const [treasuryAmount, setTreasuryAmount] = store.useState("treasuryAmount");
  const [erc20LHolders, setErc20LHolders] = store.useState("erc20LHolders");

  const [erc20RLopTotalSupply, setErc20RLopTotalSupply] = useState("");
  const [rlopSupplyLimit, setrlopSupplyLimit] = useState("");
  const [stakedAmount, setstakedAmount] = useState("0");
  const [rMintAmount, setRMintAmount] = useState("0");
  const [isLeadership, setIsLeadership] = useState(false);
  const [owner, setOwner] = useState("owner");

  useEffect(() => {
    (async () => {
      if (account) {
        const _rBalance = await READ_ERC20_R_LOP_CONTRACT.methods
          .balanceOf(account)
          .call();
        setRBalance(_rBalance?.toString() || "0");
        const _stakedAmount = await READ_DAO_CONTRACT.methods
          .stakeInfo(account)
          .call();
        setstakedAmount(
          Web3.utils.fromWei(_stakedAmount?.toString() || "", "ether")
        );
      }
    })();
  }, [account, setRBalance]);

  useEffect(() => {
    if (account) {
      (async () => {
        setIsLeadership(
          await READ_DAO_CONTRACT.methods.isLeadership(account).call()
        );
      })();
    }
  }, [account]);

  useEffect(() => {
    (async () => {
      setOwner(await READ_DAO_CONTRACT.methods.owner().call());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (account) {
        const web3: any = getHttpWeb3();
        if (web3) {
          setBalance(await web3.eth.getBalance(account));
        }
      }
    })();
  }, [account, setBalance]);

  useEffect(() => {
    (async () => {
      if (account) {
        const _usdcBalance = await READ_USDC_CONTRACT.methods
          .balanceOf(account)
          .call();
        setUsdcBalance(_usdcBalance?.toString() || "0");
      }
    })();
  }, [account, setUsdcBalance]);

  useEffect(() => {
    (async () => {
      if (account) {
        const _lBalance = await READ_ERC20LOP_CONTRACT.methods
          .balanceOf(account)
          .call();
        setLBalance(_lBalance?.toString() || "0");
        let index = 0;
        let _rMintAmount = 0;
        while (true) {
          try {
            const rMintIndex: any = await READ_DAO_CONTRACT.methods
              .rMintIndices(account, index)
              .call();
            const rMint: any = await READ_DAO_CONTRACT.methods
              .rMintList(parseInt(rMintIndex))
              .call();
            _rMintAmount += parseFloat(
              Web3.utils.fromWei(rMint.amount, "ether")
            );
            index++;
          } catch (e) {
            break;
          }
        }
        setRMintAmount(_rMintAmount + "" || "0");
      }
    })();
  }, [account, setLBalance]);

  useEffect(() => {
    (async () => {
      setErc20LHolders(await getTokenHolders(ERC20LOP_ADDRESS));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const _erc20TtoalSupply = await READ_DAO_CONTRACT.methods
        .lopTotalSupply()
        .call();
      setErc20LopTotalSupply(_erc20TtoalSupply?.toString() || "0");
      const _rlopSupplyLimit = await READ_DAO_CONTRACT.methods
        .rlopSupplyLimit()
        .call();
      setrlopSupplyLimit(
        Web3.utils.fromWei(_rlopSupplyLimit?.toString() || "", "ether")
      );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const _erc20LopPrice = await READ_ERC20LOP_CONTRACT.methods
        .tokenPrice()
        .call();
      const _erc20LopPriceDecimal = await READ_ERC20LOP_CONTRACT.methods
        .tokenPriceDecimal()
        .call();
      setErc20LopPrice(
        BigNumber(_erc20LopPrice?.toString() || "0")
          .div(BigNumber(_erc20LopPriceDecimal?.toString() || "1"))
          .toString()
      );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const rMintIndex: string = await READ_DAO_CONTRACT.methods
        .rLopMintIndex()
        .call();
      for (let i = 0; i < parseInt(rMintIndex); i++) {
        const info = await READ_DAO_CONTRACT.methods.rMintList(i).call();
        console.log(info);
      }

      const _erc20RTtoalSupply = await READ_DAO_CONTRACT.methods
        .rlopSupplyUsed()
        .call();
      setErc20RLopTotalSupply(_erc20RTtoalSupply?.toString() || "0");
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const _lopDaoPoolLimit = await READ_DAO_CONTRACT.methods
        .lopDaoPoolLimit()
        .call();
      setlopDaoPoolLimit(_lopDaoPoolLimit?.toString() || "0");
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const _treasuryAmount = await READ_USDC_CONTRACT.methods
        .balanceOf(DAO_ADDRESS)
        .call();
      setTreasuryAmount(_treasuryAmount?.toString() || "0");
    })();
  }, []);

  const viewErc20LopTotalSupply = useMemo(() => {
    return Web3.utils.fromWei(erc20LopTotalSupply?.toString() || "0", "ether");
  }, [erc20LopTotalSupply]);

  const viewrLOPused = useMemo(() => {
    return BigNumber(
      Web3.utils.fromWei(erc20RLopTotalSupply?.toString() || "0", "ether")
    ).toFixed(4);
  }, [erc20RLopTotalSupply]);

  const viewRBalance = useMemo(() => {
    return Web3.utils.fromWei(rBalance?.toString() || "0", "ether");
  }, [rBalance]);

  const viewlopDaoPoolLimit = useMemo(() => {
    return Web3.utils.fromWei(lopDaoPoolLimit?.toString() || "0", "ether");
  }, [lopDaoPoolLimit]);

  const viewLBalance = useMemo(() => {
    return Web3.utils.fromWei(lBalance?.toString() || "0", "ether");
  }, [lBalance]);

  const viewTotalBalance = useMemo(() => {
    const amount = parseFloat(stakedAmount) + parseFloat(viewLBalance);
    return amount || 0;
  }, [viewLBalance, stakedAmount]);

  const viewUsdcBalance = useMemo(() => {
    return BigNumber((usdcBalance as number) / USDC_DECIMALS).toFixed(5);
  }, [usdcBalance]);

  const viewBalance = useMemo(() => {
    return BigNumber(
      Web3.utils.fromWei(balance?.toString() || "0", "ether")
    ).toFixed(5);
  }, [balance]);

  const viewTreasuryAmount = useMemo(() => {
    const treasuryEther =
      parseFloat(treasuryAmount?.toString() || "0") / USDC_DECIMALS;
    return BigNumber(treasuryEther).toFixed(4);
  }, [treasuryAmount]);

  const viewMarketCap = useMemo(() => {
    const marketCap =
      parseFloat(erc20LopPrice as string) * parseFloat(viewErc20LopTotalSupply);
    if (isNaN(Number(marketCap))) return "0";

    return marketCap.toFixed(5).toString();
  }, [erc20LopPrice, viewErc20LopTotalSupply]);

  return (
    <Layout>
      <div
        className="page-container dashboard"
        style={{ background: BgColor.RED }}
      >
        <Image id="bg-img" src={BgImg} alt="background" />
        <div className="card-content justify-content-between px-lg-4 d-flex flex-column">
          <div className="d-xxl-flex flex-xxl-row d-xl-flex justify-content-center ">
            <div className="w-100 mt-2 mt-1 mx-1">
              <div
                className="bg-grey border-10 py-2 w-100 px-4 d-flex justify-content-between align-items-center"
                style={{ height: 90 }}
              >
                <div>
                  <p className="font-12 color-grey inter-regular m-0">
                    Matic that I own
                  </p>
                  <strong className="font-20 inter-bold">
                    {getLocaleString(viewBalance)}
                  </strong>
                </div>
                <div
                  className="card-content d-flex align-items-center px-2 py-3"
                  style={{ marginLeft: 10 }}
                >
                  <Image src={MaticlogoImg} width={16} alt="token-logo" />
                  <div
                    className="inter-bold color-grey font-12"
                    style={{ marginLeft: 5 }}
                  >
                    Matic
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 mt-2 mt-1 mx-1">
              <div
                className="bg-grey border-10 py-2 w-100 px-4 d-flex justify-content-between align-items-center"
                style={{ height: 90 }}
              >
                <div>
                  <p className="font-12 color-grey inter-regular m-0">
                    USDC that I own
                  </p>
                  <strong className="font-20 inter-bold">
                    {getLocaleString(viewUsdcBalance)}
                  </strong>
                </div>
                <div
                  className="card-content d-flex align-items-center px-2 py-3"
                  style={{ marginLeft: 10 }}
                >
                  <Image src={UsdclogoImg} width={16} alt="token-logo" />
                  <div
                    className="inter-bold color-grey font-12"
                    style={{ marginLeft: 5 }}
                  >
                    USDC
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 mt-2 mt-1 mx-1">
              <div
                className="bg-grey border-10 py-2 w-100 px-4 d-flex justify-content-between align-items-center"
                style={{ height: 90 }}
              >
                <div>
                  <p className="font-12 color-grey inter-regular m-0">
                    My staked LOP
                  </p>
                  <strong className="font-24 inter-bold">
                    {getLocaleString(stakedAmount)}
                  </strong>
                </div>
                <div
                  className="card-content d-flex align-items-center px-2 py-3"
                  style={{ marginLeft: 10 }}
                >
                  <Image src={TokenlogoImg} width={20} alt="token-logo" />
                  <div
                    className="inter-bold color-grey font-14"
                    style={{ marginLeft: 5 }}
                  >
                    LOP
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 mt-2 mt-1 mx-1">
              <div
                className="bg-grey border-10 py-2 w-100 px-4 d-flex justify-content-between align-items-center"
                style={{ height: 90 }}
              >
                <div>
                  <p className="font-12 color-grey inter-regular m-0">
                    rLOP that I own
                  </p>
                  <strong className="font-24 inter-bold">
                    {getLocaleString(viewRBalance)}
                  </strong>
                </div>
                <div
                  className="card-content d-flex align-items-center px-2 py-3"
                  style={{ marginLeft: 10 }}
                >
                  <Image src={TokenlogoImg1} width={20} alt="token-logo" />
                  <div
                    className="inter-bold color-grey font-14"
                    style={{ marginLeft: 5 }}
                  >
                    rLOP
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 mt-2 mt-1 mx-1">
              <div
                className="bg-grey border-10 py-2 w-100 px-4 d-flex justify-content-between align-items-center"
                style={{ height: 90 }}
              >
                <div>
                  <p className="font-12 color-grey inter-regular m-0">
                    LOP that I own
                  </p>
                  <strong className="font-24 inter-bold">
                    {getLocaleString(viewTotalBalance)}
                  </strong>
                </div>
                <div
                  className="card-content d-flex align-items-center px-2 py-3"
                  style={{ marginLeft: 10 }}
                >
                  <Image src={TokenlogoImg} width={20} alt="token-logo" />
                  <div
                    className="inter-bold color-grey font-14"
                    style={{ marginLeft: 5 }}
                  >
                    LOP
                  </div>
                </div>
              </div>
            </div>
          </div>
          {account ? (
            <div className="d-xxl-flex flex-xxl-row d-xl-flex justify-content-center ">
              <div className="w-100 mt-2 mt-1 mx-1 px-2">
                <div className="pt-3 mx-4 d-flex flex-row justify-content-between align-items-center">
                  <p className="inter-bold font-32">Welcome</p>
                  <p
                    className="color-grey inter-regular font-18"
                    style={{ marginLeft: 10 }}
                  >
                    ({getShortAddress(account)})
                  </p>
                </div>
              </div>
              <div className="w-100 mt-2 mt-1 mx-1"></div>
              <div className="w-100 mt-2 mt-1 mx-1"></div>
              <div className="w-100 mt-2 mt-1 mx-1">
                <Button
                  className="inter-bold font-20 w-100 border-0 border-10 mt-2"
                  style={{
                    background: BgColor.BLUE,
                    padding: "10px 0",
                    color: "#000",
                  }}
                  onClick={() => {
                    nav("/dao/stake");
                  }}
                >
                  Stake Tokens
                </Button>
              </div>
              <div className="w-100 mt-2 mt-1 mx-1">
                <Button
                  className="inter-bold font-20 w-100 border-0 border-10 mt-2"
                  style={{
                    background: BgColor.RED,
                    padding: "10px 0",
                    color: "#000",
                  }}
                  onClick={() => {
                    nav("/token");
                  }}
                >
                  Purchase more
                </Button>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <Row className="mt-3">
          <Col sm={12} lg={4}>
            <div className="card-content text-center p-lg-4">
              <h4 className="font-20 inter-bold color-red">LOP Token</h4>
              <div className="card-content bg-grey mt-4">
                <h4 className="font-20 inter-bold">
                  {getLocaleString(viewErc20LopTotalSupply)} LOP
                </h4>
                <p className="m-0 font-14 inter-regular color-grey">
                  LOP Total Supply
                </p>
              </div>
              <div className="card-content bg-grey mt-3">
                <h4 className="font-20 inter-bold">
                  ${getLocaleString(viewMarketCap)}
                </h4>
                <p className="m-0 font-14 inter-regular color-grey">
                  LOP Market cap
                </p>
              </div>
              <div className="card-content bg-grey mt-3">
                <h4 className="font-20 inter-bold">
                  $ {erc20LopPrice?.toString() || "0"}
                </h4>
                <p className="m-0 font-14 inter-regular color-grey">
                  Token price
                </p>
              </div>
              <div className="mt-3 d-flex align-items-center justify-content-center">
                <Image src={EtherscanImg} width={16} alt="etherscan" />
                <a
                  href={`${CHAIN_BLOCK_EXPLORER_URLS[0]}/address/${ERC20LOP_ADDRESS}`}
                  target="_blank"
                  className="color-grey inter-regular font-14"
                  style={{ opacity: 0.5, marginLeft: 20 }}
                  rel="noreferrer"
                >
                  View on Etherscan
                </a>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={4} className="mt-3 mt-lg-0">
            <div className="card-content text-center p-lg-4">
              <h4 className="font-20 inter-bold color-red">DAO Activity</h4>
              <div className="card-content bg-grey mt-4">
                <h4 className="font-20 inter-bold">
                  {getLocaleString((erc20LHolders as any)?.length || "0")}
                </h4>
                <p className="m-0 font-14 inter-regular color-grey">Members</p>
              </div>
              <div className="card-content bg-grey mt-4">
                <h4 className="font-20 inter-bold">
                  {getLocaleString(viewlopDaoPoolLimit) || "0"} LOP
                </h4>
                <p className="m-0 font-14 inter-regular color-grey">
                  Total LOP Allocated to the DAO
                </p>
              </div>
              {(isLeadership || owner === account) && (
                <div className="card-content bg-grey mt-3">
                  <h4 className="font-20 inter-bold">
                    ${getLocaleString(viewTreasuryAmount)}
                  </h4>
                  <p className="m-0 font-14 inter-regular color-grey">
                    Treasury
                  </p>
                </div>
              )}
              <Button
                className="inter-bold font-20 w-100 border-0 border-10 mt-3"
                style={{
                  background: BgColor.GREY,
                  padding: "24px 0",
                  color: "#000",
                }}
                onClick={() => {
                  nav("/dao/proposals");
                }}
              >
                Go to Governance
              </Button>
              <div className="mt-3 d-flex align-items-center justify-content-center">
                <Image src={EtherscanImg} width={16} alt="etherscan" />
                <a
                  href={`${CHAIN_BLOCK_EXPLORER_URLS[0]}/address/${DAO_ADDRESS}`}
                  target="_blank"
                  className="color-grey inter-regular font-14"
                  style={{ opacity: 0.5, marginLeft: 20 }}
                  rel="noreferrer"
                >
                  View on Etherscan
                </a>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={4} className="mt-3 mt-lg-0">
            <div className="card-content text-center p-lg-4">
              <h4 className="font-20 inter-bold color-red">rLOP Options</h4>
              <div className="card-content bg-grey mt-4">
                <h4 className="font-20 inter-bold">
                  {getLocaleString(rlopSupplyLimit || "0")}
                </h4>
                <p className="m-0 font-14 inter-regular color-grey">
                  Authorized rLOP Supply
                </p>
              </div>
              <div className="card-content bg-grey mt-4">
                <h4 className="font-20 inter-bold">
                  {getLocaleString(viewrLOPused)}
                </h4>
                <p className="m-0 font-14 inter-regular color-grey">
                  Authorized rLOP Supply Used
                </p>
              </div>
              {/* <div className="card-content bg-grey mt-3">
                <h4 className="font-20 inter-bold">
                  {getLocaleString(rMintAmount)}
                </h4>
                <p className="m-0 font-14 inter-regular color-grey">
                  Total rLOP options owned by you
                </p>
              </div> */}
              <div className="mt-3 d-flex align-items-center justify-content-center">
                <Image src={EtherscanImg} width={16} alt="etherscan" />
                <a
                  href={`${CHAIN_BLOCK_EXPLORER_URLS[0]}/address/${ERC20RLOP_ADDRESS}`}
                  target="_blank"
                  className="color-grey inter-regular font-14"
                  style={{ opacity: 0.5, marginLeft: 20 }}
                  rel="noreferrer"
                >
                  View on Etherscan
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Dashboard;
