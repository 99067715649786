import { useMemo, useEffect, useState } from "react";
import { Image, Button, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { BsCaretDownFill } from "react-icons/bs";
import BigNumber from "bignumber.js";
import Web3 from "web3";

import { store } from "../store";
import { DAO_ADDRESS } from "../configs/addresses";
import {
  READ_ERC20LOP_CONTRACT,
  READ_USDC_CONTRACT,
  WRITE_DAO_CONTRACT,
  WRITE_USDC_CONTRACT,
  READ_DAO_CONTRACT,
} from "../configs/smart_contracts";

import Layout from "../layout";
import { BgColor } from "../App";
import EtherscanImg from "../assets/img/etherscan.png";
import TokenlogoImg from "../assets/img/token-logo.png";
import UsdclogoImg from "../assets/img/usdc-logo.png";
import USDClogoImg from "../assets/img/usdc-logo.png";
import BgImg from "../assets/img/bg.png";
import toast from "react-hot-toast";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";
import { USDC_DECIMALS } from "../configs/constants";
import { getLocaleString } from "../utils";

function Token(props: any) {
  const [usdcAmount, setUsdcAmount] = useState("");
  const [lopPrice, setLopPrice] = useState("0");
  const [loading, setLoading] = useState(false);
  const [lopInvestmentPoolLimit, setlopInvestmentPoolLimit] = useState("");
  const [lopInvestmentPoolused, setlopInvestmentPoolused] = useState("");
  const [lopTotalSupply, setlopTotalSupply] = useState("");
  const [stakedAmount, setstakedAmount] = useState("0");

  const [lBalance, setLBalance] = store.useState("lBalance");
  const [usdcBalance, setUsdcBalance] = store.useState("usdcBalance");
  const [account] = store.useState("account");
  const [erc20LopTotalSupply, setErc20LopTotalSupply] = store.useState(
    "erc20LopTotalSupply"
  );

  useEffect(() => {
    (async () => {
      if (account) {
        const _lBalance = await READ_ERC20LOP_CONTRACT.methods
          .balanceOf(account)
          .call();
        setLBalance(_lBalance?.toString() || "0");
      }
    })();
  }, [account, setLBalance]);

  useEffect(() => {
    (async () => {
      if (account) {
        const _stakedAmount = await READ_DAO_CONTRACT.methods
          .stakeInfo(account)
          .call();
        setstakedAmount(
          Web3.utils.fromWei(_stakedAmount?.toString() || "", "ether")
        );
      }
    })();
  }, [account, setstakedAmount]);

  useEffect(() => {
    (async () => {
      if (account) {
        const _usdcBalance = await READ_USDC_CONTRACT.methods
          .balanceOf(account)
          .call();
        setUsdcBalance(_usdcBalance?.toString() || "0");
      }
    })();
  }, [account, setUsdcBalance]);

  useEffect(() => {
    getInitialData();
  }, []);

  const viewErc20LopTotalSupply = useMemo(() => {
    return Web3.utils.fromWei(erc20LopTotalSupply?.toString() || "0", "ether");
  }, [erc20LopTotalSupply]);

  const viewLBalance = useMemo(() => {
    return Web3.utils.fromWei(lBalance?.toString() || "0", "ether");
  }, [lBalance]);

  const viewTotalBalance = useMemo(() => {
    const amount = parseFloat(stakedAmount) + parseFloat(viewLBalance);
    return amount || 0;
  }, [viewLBalance, stakedAmount]);

  const viewLBalancePrice = useMemo(() => {
    return viewTotalBalance * parseFloat(lopPrice) || 0;
  }, [viewTotalBalance, lopPrice]);

  const viewUsdcBalance = useMemo(() => {
    return BigNumber((usdcBalance as number) / USDC_DECIMALS).toFixed(5);
  }, [usdcBalance]);

  const viewLopAmount = useMemo(() => {
    return (
      Math.floor((parseFloat(usdcAmount) * 100) / parseFloat(lopPrice)) / 100
    );
  }, [lopPrice, usdcAmount]);

  const getInitialData = async () => {
    const _erc20TtoalSupply = await READ_ERC20LOP_CONTRACT.methods
      .totalSupply()
      .call();
    setErc20LopTotalSupply(_erc20TtoalSupply?.toString() || "0");

    const _lopInvestmentPoolLimit = await READ_DAO_CONTRACT.methods
      .lopInvestmentPoolLimit()
      .call();
    setlopInvestmentPoolLimit(
      Web3.utils.fromWei(_lopInvestmentPoolLimit?.toString() || "", "ether")
    );
    const _lopInvestmentPoolused = await READ_DAO_CONTRACT.methods
      .lopInvestmentPoolused()
      .call();
    setlopInvestmentPoolused(
      Web3.utils.fromWei(_lopInvestmentPoolused?.toString() || "", "ether")
    );
    const _lopTotalSupply = await READ_DAO_CONTRACT.methods
      .lopTotalSupply()
      .call();
    setlopTotalSupply(
      Web3.utils.fromWei(_lopTotalSupply?.toString() || "", "ether")
    );

    const _erc20LopPrice = await READ_ERC20LOP_CONTRACT.methods
      .tokenPrice()
      .call();
    const _erc20LopPriceDecimal = await READ_ERC20LOP_CONTRACT.methods
      .tokenPriceDecimal()
      .call();
    setLopPrice(
      BigNumber(_erc20LopPrice?.toString() || "0")
        .div(BigNumber(_erc20LopPriceDecimal?.toString() || "1"))
        .toString()
    );
  };

  const swapHandler = async () => {
    if (loading) {
      return toast("Swapping token now.", customToastStyle);
    }
    if (!account) {
      return toast("Please connect your wallet to continue", customToastStyle);
    }
    if (parseFloat(usdcAmount) <= 0 || isNaN(parseFloat(usdcAmount))) {
      return toast("Please input USDC amount", customToastStyle);
    }

    const usdcBalance = await READ_USDC_CONTRACT.methods
      .balanceOf(account)
      .call();
    if (
      parseFloat(usdcBalance?.toString() || "0") / USDC_DECIMALS <
      parseFloat(usdcAmount)
    ) {
      return toast("Insufficient USDC balance", customToastStyle);
    }

    // if (BigNumber(lopTotalSupply).isLessThan(BigNumber(viewErc20LopTotalSupply?.toString() || "0").plus(BigNumber(viewLopAmount)))) {
    //   return toast("Insufficient LOP supply", customToastStyle);
    // }

    try {
      setLoading(true);

      const usdcContract = await WRITE_USDC_CONTRACT();
      await usdcContract.methods
        .approve(DAO_ADDRESS, parseFloat(usdcAmount) * USDC_DECIMALS)
        .send({
          from: account,
          gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
        });

      const daoContract = await WRITE_DAO_CONTRACT();
      await daoContract.methods
        .investUsdc(Web3.utils.toWei(usdcAmount, "ether"))
        .send({
          from: account,
          gasLimit: parseInt(process.env.REACT_APP_GAS_LIMIT || "0"),
        });

      await getInitialData();

      setLoading(false);
      setUsdcAmount("");

      return toast("Swap token successfully", customToastSuccessStyle);
    } catch (e) {
      console.error(e);
      setLoading(false);
      return toast("Error to swap token", customToastStyle);
    }
  };

  const usdcInputHandler = (e: any) => {
    if (
      (parseFloat(e.target.value) > 0 && !isNaN(parseFloat(e.target.value))) ||
      e.target.value === ""
    ) {
      const unusedPoolLimit =
        parseFloat(lopInvestmentPoolLimit) - parseFloat(lopInvestmentPoolused);
      if (parseFloat(e.target.value) / parseFloat(lopPrice) > unusedPoolLimit) {
        return toast(
          "USDC amount should be less than remaining investment amount. ",
          customToastStyle
        );
      }

      setUsdcAmount(e.target.value);
    }
  };

  return (
    <Layout>
      <div className="page-container" style={{ background: BgColor.YELLOW }}>
        <Image id="bg-img" src={BgImg} alt="background" />
        <div className="token-container d-md-flex align-items-start">
          <div className="left-side">
            <div className="card-content pt-4">
              <h2 className="font-24 inter-bold">My LOP Token</h2>
              <p className="inter-regular color-grey font-14">
                LOP tokens represent your equity in the project, the DAO and the
                platform. Token utilities include:
              </p>
              <ul className="inter-regular color-grey font-14">
                <li>DAO Governance voting rights</li>
                <li>Rewards for participating</li>
              </ul>
            </div>
            <div className="card-content mt-2 mt-md-3">
              <div className="d-flex align-items-center">
                <p className="inter-regular color-grey font-14 m-0">
                  LOP token owned
                </p>
                <Image
                  src={EtherscanImg}
                  width={16}
                  alt="etherscan"
                  className="mx-2"
                />
              </div>
              <div className="d-flex align-items-start mt-2">
                <Image src={TokenlogoImg} width={25} alt="etherscan" />
                <div className="">
                  <div className="inter-bold font-20 m-0 mx-2">
                    {getLocaleString(viewTotalBalance)} LOP
                  </div>
                  <div>({getLocaleString(viewLBalancePrice)} USDC)</div>
                </div>
              </div>
            </div>
            <div className="card-content mt-2 mt-md-3">
              <div className="d-flex align-items-center">
                <p className="inter-regular color-grey font-14 m-0">
                  USDC token owned
                </p>
                <Image
                  src={EtherscanImg}
                  width={16}
                  alt="etherscan"
                  className="mx-2"
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <Image src={UsdclogoImg} width={25} alt="etherscan" />
                <p className="inter-bold font-20 m-0 mx-2">
                  {getLocaleString(viewUsdcBalance)} USDC
                </p>
              </div>
            </div>
            <div className="card-content mt-2 mt-md-3">
              <div className="d-flex align-items-center">
                <p className="inter-regular color-grey font-14 m-0">
                  LOP Total Supply
                </p>
                <Image
                  src={EtherscanImg}
                  width={16}
                  alt="etherscan"
                  className="mx-2"
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <Image src={TokenlogoImg} width={25} alt="etherscan" />
                <p className="inter-bold font-20 m-0 mx-2">
                  {getLocaleString(lopTotalSupply)} LOP
                </p>
              </div>
            </div>
          </div>
          <div className="right-side card-content mx-md-5 px-2 d-flex justify-content-center">
            <div style={{ width: "100%", maxWidth: 435, padding: "40px 0" }}>
              <h3 className="inter-bold font-24 text-center mb-4">
                Purchase LOP Token
              </h3>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Text>
                    Total LOP Available for Investment:{" "}
                    {getLocaleString(lopInvestmentPoolLimit)}(LOP)
                  </Card.Text>
                  <Card.Text>
                    LOP Investment Tokens Purchased:{" "}
                    {getLocaleString(lopInvestmentPoolused)}(LOP)
                  </Card.Text>
                </Card.Body>
              </Card>
              <div className="card-content bg-grey px-4 d-flex justify-content-between align-items-center position-relative">
                <div>
                  <p className="m-0 font-12 color-grey inter-regular mb-1">
                    You spend
                  </p>
                  <Form.Control
                    type="text"
                    placeholder="USDC amount"
                    value={usdcAmount}
                    onChange={usdcInputHandler}
                  />
                  <p className="m-0 font-12 color-grey inter-regular mt-1">
                    $ {getLocaleString(usdcAmount || "0")}
                  </p>
                </div>
                <div className="card-content d-flex align-items-center p-2">
                  <Image src={USDClogoImg} width={25} alt="usdc" />
                  <div
                    className="inter-bold font-16 color-grey"
                    style={{ marginLeft: 5 }}
                  >
                    USDC
                  </div>
                </div>
                <div className="card-content down-arrow p-1">
                  <BsCaretDownFill fill={BgColor.GREY} size={24} />
                </div>
              </div>
              <div className="card-content bg-grey px-4 d-flex justify-content-between align-items-center mt-2">
                <div>
                  <p className="m-0 font-12 color-grey inter-regular">
                    You receive
                  </p>
                  <h3 className="inter-bold font-24 m-0">
                    {viewLopAmount || "0"}
                  </h3>
                  <p className="m-0 font-12 color-grey inter-regular">
                    $ {getLocaleString(usdcAmount || "0")}
                  </p>
                </div>
                <div className="card-content d-flex align-items-center p-2">
                  <Image src={TokenlogoImg} width={25} alt="usdc" />
                  <div
                    className="inter-bold font-16 color-grey"
                    style={{ marginLeft: 15 }}
                  >
                    LOP
                  </div>
                </div>
              </div>
              <Button
                className="inter-bold font-20 w-100 border-0 border-10 mt-2"
                style={{
                  background: BgColor.RED,
                  padding: "7px 0",
                  opacity: loading ? 0.4 : 1,
                }}
                onClick={swapHandler}
              >
                Swap
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Token;
