import React, { useEffect, useState, useMemo } from "react";
import Layout from "../layout";
import { BgColor } from "../App";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
// @ts-ignore
import { ReactHiererchyChart } from "react-hierarchy-chart";
import toast from "react-hot-toast";
import TreeModel from "tree-model";
import Web3 from "web3";

import { customToastStyle } from "../configs/constants";
import { getOrg, setOrg } from "../apis/vestApi";
import WorkProposalWithMember from "../components/WorkProposalWithMember";

import { store } from "../store";
import { READ_DAO_CONTRACT } from "../configs/smart_contracts";

const tree = new TreeModel({ childrenPropertyName: "childs" });

export default function OrgChartTree() {
  const [account] = store.useState("account");

  const [root, setRoot]: [root: any, setRoot: Function] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showWorkProposal, setshowWorkProposal] = useState(false);
  const [selectedAccount, setselectedAccount] = useState("");
  const [activeArrow, setActiveArrow] = useState("");
  const [activeNodeId, setActiveNodeId] = useState(-1);
  const [newNodeInfo, setNewNodeInfo] = useState({
    name: "",
    position: "",
    contact: "",
    walletAddress: "",
    id: uuidv4(),
  });
  const [nodes, setNodes]: [nodes: any, setNodes: Function] = useState();
  const [isLeadership, setIsLeadership] = useState(false);

  useEffect(() => {
    if (account) {
      (async () => {
        setIsLeadership(
          await READ_DAO_CONTRACT.methods.isLeadership(account).call()
        );
      })();
    }
  }, [account]);

  useEffect(() => {
    (async () => {
      setNodes(await getOrg());
    })();
  }, []);

  useEffect(() => {
    if (nodes) {
      setRoot(tree.parse(nodes));
    }
  }, [nodes]);

  const viewNodes = useMemo(() => {
    if (root) {
      const data = root.all();
      return [data[0].model];
    }
    return [];
  }, [root]);

  function createNodeBottom(_selectedId: number, data: any) {
    console.log(_selectedId);
    const rootData: any = root.all();
    let newTree: any = tree.parse(rootData[0].model);
    let selectedNode: any = newTree.first(function (node: any) {
      return node.model.id === _selectedId;
    });
    const newNode = tree.parse(data);
    selectedNode.addChild(newNode);
    const rootDataNew: any = newTree.all();
    setRoot(tree.parse(rootDataNew[0].model));
  }

  function createNodeNew(_selectedId: number, data: any) {
    const rootData: any = root.all();
    let newTree: any = tree.parse(rootData[0].model);
    const newNode = tree.parse(data);
    newTree.addChild(newNode);
    const rootDataNew: any = newTree.all();
    setRoot(tree.parse(rootDataNew[0].model));
  }

  function createNodeLeft(_selectedId: number, data: any) {
    const rootData: any = root.all();
    let newTree: any = tree.parse(rootData[0].model);
    let selectedNode: any = newTree.first(function (node: any) {
      return node.model.id === _selectedId;
    });
    const newNode = tree.parse(data);
    let parent = selectedNode.parent;
    const parentIndex = parent.getIndex();
    parent.addChildAtIndex(newNode, parentIndex);
    const rootDataNew: any = newTree.all();
    setRoot(tree.parse(rootDataNew[0].model));
  }

  async function createNodeRight(_selectedId: number, data: any) {
    const rootData: any = root.all();
    let newTree: any = tree.parse(rootData[0].model);
    let selectedNode: any = newTree.first(function (node: any) {
      return node.model.id === _selectedId;
    });
    const newNode = tree.parse(data);
    let parent = selectedNode.parent;
    const parentIndex = parent.getIndex();
    parent.addChildAtIndex(newNode, parentIndex + 1);
    const rootDataNew: any = newTree.all();
    setRoot(tree.parse(rootDataNew[0].model));
  }

  async function deleteNode(_selectedId: number) {
    let confirm = window.confirm("Do you want to delete item?");
    if (!confirm) {
      return;
    }
    const rootData: any = root.all();
    let newTree: any = tree.parse(rootData[0].model);
    let selectedNode: any = newTree.first(function (node: any) {
      return node.model.id === _selectedId;
    });
    selectedNode.drop();
    const rootDataNew: any = newTree.all();
    setRoot(tree.parse(rootDataNew[0].model));
    await setOrg(JSON.stringify(viewNodes[0]));
  }

  async function saveHandler() {
    if (!newNodeInfo.name) {
      return toast("Please input name.", customToastStyle);
    }
    if (!newNodeInfo.position) {
      return toast("Please input position.", customToastStyle);
    }
    if (!newNodeInfo.contact) {
      return toast("Please input contact.", customToastStyle);
    }
    if (!newNodeInfo.walletAddress) {
      return toast("Please input wallet address.", customToastStyle);
    }
    if (!Web3.utils.isAddress(newNodeInfo.walletAddress)) {
      return toast("Please input correct address.", customToastStyle);
    }
    switch (activeArrow) {
      case "left":
        createNodeLeft(activeNodeId, newNodeInfo);
        break;
      case "right":
        createNodeRight(activeNodeId, newNodeInfo);
        break;
      case "bottom":
        createNodeBottom(activeNodeId, newNodeInfo);
        break;
      case "new":
        createNodeNew(activeNodeId, newNodeInfo);
        break;
      default:
    }
    setNewNodeInfo({
      ...newNodeInfo,
      ...{ name: "", walletAddress: "", position: "", contact: "" },
    });
    setShowModal(false);
    await setOrg(JSON.stringify(viewNodes[0]));
  }

  return (
    <Layout>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Input personal info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={newNodeInfo?.name}
              onChange={(e: any) => {
                setNewNodeInfo({ ...newNodeInfo, ...{ name: e.target.value } });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              value={newNodeInfo?.position}
              onChange={(e: any) => {
                setNewNodeInfo({
                  ...newNodeInfo,
                  ...{ position: e.target.value },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="text"
              value={newNodeInfo?.contact}
              onChange={(e: any) => {
                setNewNodeInfo({
                  ...newNodeInfo,
                  ...{ contact: e.target.value },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Wallet Address</Form.Label>
            <Form.Control
              type="text"
              value={newNodeInfo?.walletAddress}
              onChange={(e: any) => {
                setNewNodeInfo({
                  ...newNodeInfo,
                  ...{ walletAddress: e.target.value },
                });
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              saveHandler();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <WorkProposalWithMember
        show={showWorkProposal}
        handleClose={() => setshowWorkProposal(false)}
        selectedAccount={selectedAccount}
      />
      <div className="page-container" style={{ background: BgColor.BLUE }}>
        <div
          id="treeWrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
          }}
        >
          {!viewNodes[0]?.childs?.length && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  if (isLeadership) {
                    setActiveNodeId(-1);
                    setActiveArrow("new");
                    setShowModal(true);
                  }
                }}
                disabled={!isLeadership}
              >
                Create Org
              </Button>
            </div>
          )}
          {viewNodes[0]?.childs?.length > 0 && (
            <ReactHiererchyChart
              nodes={viewNodes}
              direction="vertical"
              randerNode={(node: any) => {
                if (node.name) {
                  return (
                    <div className="node-template" key={node?.id}>
                      <div
                        className="left"
                        onClick={() => {
                          if (isLeadership) {
                            setActiveNodeId(node?.id);
                            setActiveArrow("left");
                            setShowModal(true);
                          } else {
                            return toast(
                              "Only leadership member can manage org chart.",
                              customToastStyle
                            );
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753" />
                        </svg>
                      </div>
                      <div
                        className="right"
                        onClick={() => {
                          if (isLeadership) {
                            setActiveNodeId(node?.id);
                            setActiveArrow("right");
                            setShowModal(true);
                          } else {
                            return toast(
                              "Only leadership member can manage org chart.",
                              customToastStyle
                            );
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753" />
                        </svg>
                      </div>
                      <div
                        className="top"
                        onClick={() => {
                          if (isLeadership) {
                            deleteNode(node?.id);
                          } else {
                            return toast(
                              "Only leadership member can manage org chart.",
                              customToastStyle
                            );
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="16px"
                          height="16px"
                        >
                          <path d="M 6.496094 1 C 5.675781 1 5 1.675781 5 2.496094 L 5 3 L 2 3 L 2 4 L 3 4 L 3 12.5 C 3 13.328125 3.671875 14 4.5 14 L 10.5 14 C 11.328125 14 12 13.328125 12 12.5 L 12 4 L 13 4 L 13 3 L 10 3 L 10 2.496094 C 10 1.675781 9.324219 1 8.503906 1 Z M 6.496094 2 L 8.503906 2 C 8.785156 2 9 2.214844 9 2.496094 L 9 3 L 6 3 L 6 2.496094 C 6 2.214844 6.214844 2 6.496094 2 Z M 5 5 L 6 5 L 6 12 L 5 12 Z M 7 5 L 8 5 L 8 12 L 7 12 Z M 9 5 L 10 5 L 10 12 L 9 12 Z" />
                        </svg>
                      </div>
                      <div
                        className="bottom"
                        onClick={() => {
                          if (isLeadership) {
                            setActiveNodeId(node?.id);
                            setActiveArrow("bottom");
                            setShowModal(true);
                          } else {
                            return toast(
                              "Only leadership member can manage org chart.",
                              customToastStyle
                            );
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-down"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659" />
                        </svg>
                      </div>
                      {/* <img src={Avatar} alt="avatar" /> */}
                      <div className="detail">
                        <span className="title">{node?.name}</span>
                        <span className="position">{node?.position}</span>
                        <span>{node?.contact}</span>
                        <span
                          className="email"
                          onClick={() => {
                            if (isLeadership) {
                              setshowWorkProposal(true);
                              setselectedAccount(node?.walletAddress);
                            }
                          }}
                        >
                          {node?.walletAddress}
                        </span>
                      </div>
                    </div>
                  );
                }
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}
