import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Blockies from "react-blockies";
import Web3 from "web3";
import DataTable from "react-data-table-component";

import { READ_DAO_CONTRACT } from "../../configs/smart_contracts";
import { store } from "../../store";
import BigNumber from "bignumber.js";
import { getLocaleString } from "../../utils";


function Swaps(props: any) {

    const [vestingIndex, setVestingIndex] = useState(0);
    const [vestings, setVestings]: [vestings: any, setVestings: Function] = useState([]);

    const [account] = store.useState("account");

    const _getInitialData = async () => {
        const _vestingIndex: any = await READ_DAO_CONTRACT.methods.swapIndex().call();
        setVestingIndex(parseInt(_vestingIndex) || 0);
    }

    const _getVestingInfo = async () => {
        const _vestings: any = [];

        for (let i = 0; i < vestingIndex; i++) {
            const swapInfo: any = await READ_DAO_CONTRACT.methods.swapList(i).call();
            const amount = BigNumber(Web3.utils.fromWei(swapInfo?.rAmount, "ether")).toFixed(5);
            const type = swapInfo?.swapType ? "LOP" : "USDC"
            const swappedAmount = BigNumber(Web3.utils.fromWei(swapInfo?.swappedAmount, "ether")).toFixed(5);
            const to = swapInfo?.to || "";

            _vestings.push({ amount, type, swappedAmount, to, index: _vestings.length });
        }

        setVestings(_vestings);
    }


    useEffect(() => {
        _getInitialData();
    }, [account]);

    useEffect(() => {
        _getVestingInfo();
    }, [vestingIndex]);

    return (
        <div>
            <DataTable
                columns={[
                    {
                        name: 'No',
                        selector: (row: any) => row?.index + 1,
                        sortable: true,
                    },
                    {
                        name: 'To',
                        selector: (row: any) => row?.to,
                        sortable: true,
                    },
                    {
                        name: 'Amount',
                        selector: (row: any) => `${getLocaleString(row?.amount)} rLOP`,
                        sortable: true,
                    },
                    {
                        name: 'Swapped Amount',
                        selector: (row: any) => `${getLocaleString(row?.swappedAmount)} ${row?.type}`,
                        sortable: true,
                    },
                ]}
                data={vestings as any}
            />
        </div >
    );
}

export default Swaps;
